import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../redux/store/store'
import ButtonsTransactionReports from '../../../../components/TransactionBugs/ButtonsTransactionReports/ButtonsTransactionReports'
import HeaderTableTransactionReport from '../../../../components/TransactionBugs/HeaderTableTransactionReport/HeaderTableTransactionReport'
import TransactionReportRows from '../../../../components/TransactionBugs/TransactionReportRows/TransactionReportRows'
import PaginationTransactionReportTableRow from '../../../../components/TransactionBugs/PaginationTransactionReportTableRow/PaginationTransactionReportTableRow'
import TabBarTransactionReports from '../../../../components/TransactionBugs/TabBarTransactionReports/TabBarTransactionReports'
import { useEffect } from 'react'
import { resetManageTransactionBugReportsReducer } from '../../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'


const TransactionsBugsScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch( resetManageTransactionBugReportsReducer() )
        }
    }, [])
    
  
    return (
        <div className="flex flex-d-column height-100 p-relative">
            <p className="title-screen">{"Manage transaction bug"}</p>
            <ButtonsTransactionReports />
            <TabBarTransactionReports />
            <HeaderTableTransactionReport />
            <TransactionReportRows />
            <PaginationTransactionReportTableRow />
        </div>
    )
}

export default TransactionsBugsScreen