import React, { useEffect } from 'react'
import "./ButtonsTransactionReports.css"
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { StatusTransactionReport, statusTransactionReportValues } from '../../../utils/dbModels'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { canBeInteger } from '../../../utils/verifications/strings'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'
import { GetTransactionBugReportsThunk, TransactionTypeReport } from '../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'

const ButtonsTransactionReports = () => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const limit = useSelector((state: RootState) => state.transactionReports.limit);
    const status = useSelector((state: RootState) => state.transactionReports.status);
    const type = useSelector((state: RootState) => state.transactionReports.type);
    const LastEvaluatedKey = useSelector((state: RootState) => state.transactionReports.LastEvaluatedKey);
    const totalCounterStatus = useSelector((state: RootState) => state.transactionReports.totalCounterStatus);

    const hasAccessDisplay = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Transaction_Reports", id_Action: "Display" })

    const handleGetReports = (newStatus: StatusTransactionReport) => {


        navigate(`/transactions-bugs?status=${newStatus}&limit=${limit}&type=${type}`)
        /*
        dispatch(GetBugReportsThunk({ 
            LastEvaluatedKey: "", 
            page: 1, 
            limit, 
            bringTotal: true,
            status: newStatus
        }))
            */
    }

    useEffect(() => {

        // Get a specific parameter value
        const limitParam = searchParams.get("limit") ?? "10"
        //const pageParam = searchParams.get("General:page") ?? "1"
        const statusParam = searchParams.get("status") ?? ""

        const typeParam = searchParams.get("type") ?? ""

        let status: StatusTransactionReport = "OPENED"
        if(statusTransactionReportValues.includes(statusParam as StatusTransactionReport ))
            status = statusParam as StatusTransactionReport

        let type: TransactionTypeReport = "Billing"
        if(["Billing", "Payment"].includes(typeParam as TransactionTypeReport ))
            type = typeParam as TransactionTypeReport

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        /*
        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
*/
        //setTab({ index })
        //dispatch(changeIndexTabReducer({ indexTab: index }))
            //console.log("last", LastEvaluatedKey, page)
        dispatch(GetTransactionBugReportsThunk({ page: 1, limit, bringTotal: true, status, type }))

    }, [ searchParams.get("status"), searchParams.get("limit"), searchParams.get("type")])

    return (
        <div className="flex mt-24">

            {
                hasAccessDisplay &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.opened ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("OPENED")} className={`${status !== "OPENED" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`} >
                        {t("Reports:bug:label_01")}
                    </button>
                </div>
            }

            {
                hasAccessDisplay &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.pending ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("PENDING")} className={`${status !== "PENDING" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                        {t("Reports:bug:label_02")}
                    </button>
                </div>
            }

            {
                hasAccessDisplay &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.closed ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("CLOSED")} className={`${status !== "CLOSED" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                        {t("Reports:bug:label_03")}
                    </button>
                </div>
            }


        </div>
    )
}

export default React.memo( ButtonsTransactionReports )