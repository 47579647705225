import React from 'react';
import { useAppDispatch } from '../../../../../../redux/store/store';
import "./ContentInnerImage.css"
import { CategoryJob } from '../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import { S3DomainGimwork_categories_logo } from '../../../../../../utils/staticVariables/staticVariables';
import { setAddNewJobState, setDeleteCategoryState, setEditCategoryState } from '../../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useCheckAccess from '../../../../../hooks/useCheckAccess/useCheckAccess';
import useGetJobName from '../../../../../hooks/useGetJobName/useGetJobName';

interface ContentInnerImageProps {
    categoryJob: CategoryJob,
    dropdownStatus: string | null,
    setDropdownStatus: React.Dispatch<React.SetStateAction<string | null>>
}



const ContentInnerImage = ({ categoryJob, dropdownStatus, setDropdownStatus }: ContentInnerImageProps) => {
  
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const accessEditCategory = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Category", id_Action: "Edit" })
    const accessDeleteCategory = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Category", id_Action: "Delete" })
    const accessDisplayJobs = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Job", id_Action: "Display" })

    const categoryName = useGetJobName(categoryJob.id)

    const handleOpenDropdown = () => {
        if(dropdownStatus === "close" || !dropdownStatus)
            setDropdownStatus("open")
        else
            setDropdownStatus("close")
    }

    const openDeleteCategoryModal = () => {
        dispatch(setDeleteCategoryState({ 
            categoryId: categoryJob.id, 
            categoryName: categoryJob.categoryName, 
            countryName: categoryJob.country
        }))
    }

    const openEditCategoryModal = () => {
        dispatch(setEditCategoryState({ 
            categoryId: categoryJob.id, 
            categoryName: categoryJob.categoryName, 
            country: categoryJob.country,
            imgKey: categoryJob.imgKey,
            photoKey: categoryJob.photoKey
        }))
    }

    const openAddNewJobModal = () => {
        dispatch(setAddNewJobState({
            categoryId: categoryJob.id,
            categoryName: categoryJob.categoryName,
            imgKey: categoryJob.imgKey
        }))
    }

    return (
        <div className="container-inner-img">
            <div className="flex flex-ai-center flex-jc-sb">
                <img 
                    src={`${S3DomainGimwork_categories_logo}${categoryJob.id}/categoryImg`}
                    alt="job" 
                    className={"little-img-dropdown"}
                    /*
                        className={`little-img-dropdown
                            ${dropdownStatus === "open" && "little-img-dropdown-open br-bar-jobs" }
                            ${dropdownStatus === "close" &&  "little-img-dropdown-close"}
                        `}
                    */
                />
                <div className="container-job-icon">
                    <img 
                        src={`${S3DomainGimwork_categories_logo}${categoryJob.id}/categoryImg_icon`} 
                        alt={categoryJob.photoKey} 
                        className="img-icon-job"
                    />
                </div>
                <p className="c-white fs-18 ff-roboto">{categoryName}</p>
                { categoryJob.jobs.length < 1 && <p className="fs-14 c-white ml-28 mt-2"><i>- {t("General:label_102")} -</i></p>  }
            </div>

            <div className="flex flex-ai-center">

                {
                    accessEditCategory &&
                    <>
                        <button onClick={openAddNewJobModal} className="btn-add-new-job">
                            <FontAwesomeIcon icon={regular("plus")} className="c-white fs-16" />
                            <p className="fs-14 fw-400 c-white ml-6">{t("General:label_101")}</p>
                        </button>
                        <div className="vertical-line-job ml-26 mr-12"></div>
                    </>

                }

                
                {
                    accessEditCategory &&
                    <button onClick={openEditCategoryModal} className="bg-transparent ml-8 mr-10 pointer">
                        <FontAwesomeIcon icon={solid("pen")} className="c-white fs-20" />
                    </button>
                }
                {
                    accessDeleteCategory &&
                    <button onClick={openDeleteCategoryModal} className="bg-transparent ml-8 mr-8 pointer">
                        <FontAwesomeIcon icon={solid("trash-can")} className="c-white fs-20" />
                    </button>
                }
                
                

                {
                    accessDisplayJobs &&
                    <>
                        <div className="vertical-line-job mr-26 ml-10"></div>
                        <button
                            onClick={handleOpenDropdown} 
                            className="bg-transparent center pointer"
                        >
                            { 
                                dropdownStatus === "open" 
                                ? <FontAwesomeIcon icon={solid("caret-up")} className="c-white fs-20 mr-20" />
                                : <FontAwesomeIcon icon={solid("caret-down")} className="c-white fs-20 mr-20" />
                            }
                        </button>
                    </>
                }

            </div>

        </div>
    )
}

export default ContentInnerImage