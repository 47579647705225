import { Data_Rol_Table, Id_Action, Id_Category_Role, Id_Policy, Policy } from "../redux/slices/components/ManageRoles/manageRolesSlice"

export type PolicyIds = {
    id_Category: Id_Category_Role
    id_Policy: Id_Policy
    id_Action: Id_Action
}

export const policies: Policy[] = [
    // ACCESS MANAGEMENT

    // My Profile
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user/{id}", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Delete",
        "url_Access": [

        ],
        "label": ""
    },

    // My History
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_History",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Sessions
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Sessions",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Marketplace
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Marketplace",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Documents
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Documents",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },



    // DASHBOARD
    {
        "id_Category": "Dashboard",
        "id_Policy": "Export",
        "id_Action": "Display",
        "url_Access": [],
        "label": ""
    },
    {
        "id_Category": "Dashboard",
        "id_Policy": "Indicator",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Dashboard",
        "id_Policy": "Pie_Chart",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // MANAGE ROLES
    //Roles
    {
        "id_Category": "Manage_Roles",
        "id_Policy": "Roles",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/roles", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Roles",
        "id_Policy": "Roles",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/roles", "method": "POST" },
            { "url": "/v1/roles/{id}", "method": "PATCH" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Roles",
        "id_Policy": "Roles",
        "id_Action": "Delete",
        "url_Access": [
            { "url": "/v1/roles/{id}", "method": "DELETE" }
        ],
        "label": ""
    },


    // MANAGE USERS
    // Messages
    {
        "id_Category": "Manage_Users",
        "id_Policy": "Message",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Users",
        "id_Policy": "Message",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/notify/notif-email-user", "method": "POST" }
        ],
        "label": ""
    },

    // Users
    {
        "id_Category": "Manage_Users",
        "id_Policy": "Users",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user", "method": "GET" },
            { "url": "/v1/user/{id}", "method": "GET" },
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Users",
        "id_Policy": "Users",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Users",
        "id_Policy": "Users",
        "id_Action": "Delete",
        "url_Access": [

        ],
        "label": ""
    },



    // MANAGE JOBS
    // Category
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Category",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/job-category", "method": "GET" },
            { "url": "/v1/job-category/images", "method": "POST" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Category",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/job-category", "method": "POST" },
            { "url": "/v1/job-category/{id}", "method": "PATCH" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Category",
        "id_Action": "Delete",
        "url_Access": [
            { "url": "/v1/job-category/{id}", "method": "DELETE" }
        ],
        "label": ""
    },

    // Job
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Job",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/job-category", "method": "GET" },
            { "url": "/v1/job-category/images", "method": "POST" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Job",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/job-category/{id}/job", "method": "POST" },
            { "url": "/v1/job-category/{id}/job/{jobid}", "method": "PUT" },
            { "url": "/v1/job-category/{id}/jobs", "method": "PATCH" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Job",
        "id_Action": "Delete",
        "url_Access": [
            { "url": "/v1/job-category/{id}/job/{jobid}", "method": "DELETE" },
            { "url": "/v1/job-category/{id}/jobs", "method": "DELETE" }
        ],
        "label": ""
    },

    // Activate / Deactivate
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Activate_Deactivate",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Activate_Deactivate",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/job-category/{id}/job/{jobid}/activate", "method": "PATCH" },
        ],
        "label": ""
    },

    // Link to other jobs
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Link_Jobs",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Link_Jobs",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Jobs",
        "id_Policy": "Link_Jobs",
        "id_Action": "Delete",
        "url_Access": [

        ],
        "label": ""
    },



    // MANAGE ALL OFFERS
    // Task
    {
        "id_Category": "Manage_Offers",
        "id_Policy": "Task",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/offers", "method": "GET" },
            { "url": "/v1/offers/{id}", "method": "GET" },
            { "url": "/v1/user/{id}/skill-pictures", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Offers",
        "id_Policy": "Task",
        "id_Action": "Delete",
        "url_Access": [
            { "url": "/v1/offers/{id}", "method": "DELETE" }
        ],
        "label": ""
    },

    // Job
    {
        "id_Category": "Manage_Offers",
        "id_Policy": "Job",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/offers", "method": "GET" },
            { "url": "/v1/offers/{id}", "method": "GET" },
            { "url": "/v1/user/{id}/skill-pictures", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Offers",
        "id_Policy": "Job",
        "id_Action": "Delete",
        "url_Access": [
            { "url": "/v1/offers/{id}", "method": "DELETE" }
        ],
        "label": ""
    },


    
    // VERIFY
    // Profile Picture
    {
        "id_Category": "Verify",
        "id_Policy": "Profile_Picture",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user/{id}/profile-img", "method": "GET" },
            { "url": "/v1/user/users-profile-img-not-verified", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Verify",
        "id_Policy": "Profile_Picture",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/user/{id}/verify-profile-img", "method": "PATCH" }
        ],
        "label": ""
    },

    // Id
    {
        "id_Category": "Verify",
        "id_Policy": "Id",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user/{id}/id-img", "method": "GET" },
            { "url": "/v1/user/users-id-img-not-verified", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Verify",
        "id_Policy": "Id",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/user/{id}/verify-id-img", "method": "PATCH" }
        ],
        "label": ""
    },

    // Certificate
    {
        "id_Category": "Verify",
        "id_Policy": "Certificate",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user/{id}/certificate-img", "method": "GET" },
            { "url": "/v1/user/users-certificates-not-verified", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Verify",
        "id_Policy": "Certificate",
        "id_Action": "Edit",
        "url_Access": [
            { "url": "/v1/user/{id}/certificate-img/{jobid}", "method": "PATCH" }
        ],
        "label": ""
    },



    // MANAGEMENT REPORTS AND BUG REPORTS
    // Reports
    {
        "id_Category": "Manage_Reports",
        "id_Policy": "Reports",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/reports/", "method": "GET" },
        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Reports",
        "id_Policy": "Reports",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },

    // Bug Reports
    {
        "id_Category": "Manage_Reports",
        "id_Policy": "Bug_Reports",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Manage_Reports",
        "id_Policy": "Bug_Reports",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },


        // Bug Reports Tester
        {
            "id_Category": "Manage_Reports",
            "id_Policy": "Bug_Reports_Tester",
            "id_Action": "Display",
            "url_Access": [
    
            ],
            "label": ""
        },
        {
            "id_Category": "Manage_Reports",
            "id_Policy": "Bug_Reports_Tester",
            "id_Action": "Edit",
            "url_Access": [
    
            ],
            "label": ""
        },

        // Bug Reports Tester
        {
            "id_Category": "Manage_Reports",
            "id_Policy": "Bug_Reports_Developer",
            "id_Action": "Display",
            "url_Access": [
    
            ],
            "label": ""
        },
        {
            "id_Category": "Manage_Reports",
            "id_Policy": "Bug_Reports_Developer",
            "id_Action": "Edit",
            "url_Access": [
    
            ],
            "label": ""
        },


]









export const data_roles: Array<Data_Rol_Table> = [
    /*
    {
        "id_Category": "Access_Management",
        "label": "",
        "actions": ["Display", "Edit"],
        "policies": [
            { 
                "id_Policy": "My_Profile",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, { "action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "My_History",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            },
            { 
                "id_Policy": "My_Sessions",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            },
            { 
                "id_Policy": "My_Marketplace",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            },
            { 
                "id_Policy": "My_Documents",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            },
        ]
    },
    */
    {
        "id_Category": "Dashboard",
        "label": "",
        "actions": ["Display"],
        "policies": [
            { 
                "id_Policy": "Export",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }]
            },
            { 
                "id_Policy": "Indicator",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            },
            { 
                "id_Policy": "Pie_Chart",
                "label": "",
                "actions": [{"action": "Display", "selected": false }]
            }
        ]
    },
    {
        "id_Category": "Manage_Roles",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Roles",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false }]
            },
        ]
    },
    {
        "id_Category": "Manage_Users",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Message",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }]
            },
            { 
                "id_Policy": "Notification",
                "label": "",
                "actions": [ {"action": "Edit", "selected": false }]
            },
            { 
                "id_Policy": "Users",
                "label": "",
                "actions": [{"action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false }]
            }
        ]
    },
    {
        "id_Category": "Manage_Jobs",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Category",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false }]
            },
            { 
                "id_Policy": "Job",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false }]
            },
            { 
                "id_Policy": "Activate_Deactivate",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }]
            },
            { 
                "id_Policy": "Link_Jobs",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false }]
            },
        ]
    },
    {
        "id_Category": "Manage_Offers",
        "label": "",
        "actions": ["Display", "Delete"],
        "policies": [
            { 
                "id_Policy": "Task",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Delete", "selected": false } ]
            },
            { 
                "id_Policy": "Job",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Delete", "selected": false } ]
            }
        ]
    },
    {
        "id_Category": "Verify",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Profile_Picture",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Id",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Certificate",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Products",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false } ]
            }
        ]
    },
    {
        "id_Category": "Manage_Reports",
        "label": "",
        "actions": ["Display", "Edit"],
        "policies": [
            { 
                "id_Policy": "Reports",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Bug_Reports",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Bug_Reports_Tester",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            },
            { 
                "id_Policy": "Bug_Reports_Developer",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            }
        ]
    },
    {
        "id_Category": "Manage_Products",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Products",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false } ]
            }
        ]
    },
    {
        "id_Category": "Rules",
        "label": "",
        "actions": ["Display", "Edit", "Delete"],
        "policies": [
            { 
                "id_Policy": "Contracts",
                "label": "",
                "actions": [{ "action": "Display", "selected": false },  {"action": "Edit", "selected": false }, {"action": "Delete", "selected": false } ]
            }
        ]
    },

   /*  {
        "id_Category": "General_Information",
        "label": "",
        "actions": ["Display", "Edit"],
        "policies": [
            { 
                "id_Policy": "Shop_Fees",
                "label": "",
                "actions": [{ "action": "Display", "selected": false }, {"action": "Edit", "selected": false } ]
            }
        ]
    }, */

    {
        "id_Category": "Users_Form",
        "label": "",
        "actions": ["Display", "Delete"],
        "policies": [
            { 
                "id_Policy": "Users_Form",
                "label": "",
                "actions": [{ "action": "Display", "selected": false },  {"action": "Delete", "selected": false } ]
            }
        ]
    },
]




export const user_rol_policies: Policy[] = [
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Display",
        "url_Access": [
            { "url": "/v1/user/{id}", "method": "GET" }
        ],
        "label": ""
    },
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Edit",
        "url_Access": [

        ],
        "label": ""
    },
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Profile",
        "id_Action": "Delete",
        "url_Access": [

        ],
        "label": ""
    },

    // My History
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_History",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Sessions
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Sessions",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Marketplace
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Marketplace",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

    // My Documents
    {
        "id_Category": "Access_Management",
        "id_Policy": "My_Documents",
        "id_Action": "Display",
        "url_Access": [

        ],
        "label": ""
    },

]