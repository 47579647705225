
import { PolicyIds } from '../roles';
import { mainRouteAdmin } from '../staticVariables/staticVariables';


export type ISideBarOption = {
    name: string,
    icon: string,
    path: string,
    role: PolicyIds[],
    numberNotifications?: number,
    color?: string,
}

export const sidebarOptionsGeneral: ISideBarOption[] = [
    {
        name: "040",
        icon: "FaUserAlt",
        path: `${mainRouteAdmin}/my-profile`,
        role: [
            { id_Category: "Access_Management", id_Policy: "My_Profile", id_Action: "Display" }
        ],
    },
    /*
    {
        name: "041",
        icon: "FaListAlt",
        path: `${mainRouteAdmin}/my-history`,
        role: [
            { id_Category: "Access_Management", id_Policy: "My_History", id_Action: "Display" }
        ],
    },
    {
        name: "042",
        icon: "FaComments",
        path: `${mainRouteAdmin}/my-sessions`,
        role: [
            { id_Category: "Access_Management", id_Policy: "My_Sessions", id_Action: "Display" }
        ],
    },
    {
        name: "043",
        icon: "FaShop",
        path: `${mainRouteAdmin}/my-shop`,
        role: [
            { id_Category: "Access_Management", id_Policy: "My_Marketplace", id_Action: "Display" }
        ],
        color: "#6290C3"
    }
        */
]


export const sidebarOptionsGeneralSuperAdmin: ISideBarOption[] = [
    {
        name: "274",
        icon: "Form",
        path: `${mainRouteAdmin}/users-form`,
        role: [
            { id_Category: "Users_Form", id_Policy: "Users_Form", id_Action: "Display" }
        ],
    },
    {
        name: "251",
        icon: "FaWallet",
        path: `${mainRouteAdmin}/dashboard`,
        role: [
            { id_Category: "Dashboard", id_Policy: "Indicator", id_Action: "Display" }
        ],
    },
    {
        name: "045",
        icon: "FaBriefcase",
        path: `${mainRouteAdmin}/manage-jobs`,
        role: [
            { id_Category: "Manage_Jobs", id_Policy: "Category", id_Action: "Display" }
        ],
    },
    {
        name: "048",
        icon: "FaFlag",
        path: `${mainRouteAdmin}/manage-offers?limit=10&page=1&type=tasks`,
        role: [
            { id_Category: "Manage_Offers", id_Policy: "Task", id_Action: "Display" },
            { id_Category: "Manage_Offers", id_Policy: "Job", id_Action: "Display" }
        ],
    },
    {
        name: "273",
        icon: "ManageProducts",
        path: `${mainRouteAdmin}/manage-products`,
        role: [
            { id_Category: "Manage_Products", id_Policy: "Products", id_Action: "Display" }
        ],
    },
    {
        name: "047",
        icon: "FaUsers",
        path: `${mainRouteAdmin}/manage-users`,
        role: [
            { id_Category: "Manage_Users", id_Policy: "Users", id_Action: "Display" }
        ],
    },
    {
        name: "046",
        icon: "FaUserCog",
        path: `${mainRouteAdmin}/manage-roles`,
        role: [
            { id_Category: "Manage_Roles", id_Policy: "Roles", id_Action: "Display" }
        ],
    },
    {
        name: "049",
        icon: "BsPersonSquare",
        path: `${mainRouteAdmin}/verify-profile-picture`,
        role: [
            { id_Category: "Verify", id_Policy: "Profile_Picture", id_Action: "Display" }
        ],
    },
    {
        name: "050",
        icon: "FaIdCard",
        path: `${mainRouteAdmin}/verify-id`,
        role: [
            { id_Category: "Verify", id_Policy: "Id", id_Action: "Display" }
        ],
    },
    {
        name: "051",
        icon: "FaCertificate",
        path: `${mainRouteAdmin}/verify-certificate`,
        role: [
            { id_Category: "Verify", id_Policy: "Certificate", id_Action: "Display" }
        ],
    },
    {
        name: "272",
        icon: "VerifyProduct",
        path: `${mainRouteAdmin}/verify-product`,
        role: [
            { id_Category: "Verify", id_Policy: "Products", id_Action: "Display" }
        ],
    },
    {
        name: "052",
        icon: "FaBalanceScale",
        path: `${mainRouteAdmin}/manage-reports`,
        role: [
            { id_Category: "Manage_Reports", id_Policy: "Reports", id_Action: "Display" }
        ],
    },
    {
        name: "053",
        icon: "FaBug",
        path: `${mainRouteAdmin}/manage-bug-reports`,
        role: [
            { id_Category: "Manage_Reports", id_Policy: "Bug_Reports", id_Action: "Display" },
            { id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Display" },
            { id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Display" }
        ],
    },
    {
        name: "254",
        icon: "FaScrollOld",
        path: `${mainRouteAdmin}/rules`,
        role: [
            { id_Category: "Rules", id_Policy: "Contracts", id_Action: "Display" }
        ],
    },
    {
        name: "283",
        icon: "TransactionBug",
        path: `${mainRouteAdmin}/transactions-bugs?type=Billing&status=OPENED`,
        role: [
            { id_Category: "Manage_Reports", id_Policy: "Transaction_Reports", id_Action: "Display" }
        ],
    },


]