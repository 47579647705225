import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { getUser, getUsers, getUsersBySearch } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'


export type ExtraDataUser = {
    LastEvaluatedKey?: string,
    page: number,
    limit: number,
    bringTotal: boolean,
    stringToSearch?: string
}


export type ManageUser = {
    countryName:            string,
    firstName:              string,
    id:                     string,
    isProfilePicUploaded:   boolean,
    lastName:               string,
    mail:                   string,
    roleId:                 string,
    imgUrl:                 string,
}




export const GetUsersThunk = createAsyncThunk(
    'manageUsersData/GetUsersThunk',
    async ({ LastEvaluatedKey, page, limit, bringTotal }: ExtraDataUser) => {
      try { 
        const res = await getUsers({ LastEvaluatedKey, limit, bringTotal, type: "ManageUsers" })
        //console.log("res", res )
        res.results = res.results.map((user: ManageUser) => {
          //addRemainingFieldsOffer(offer)
          return user
        })
        return {
          results: res.results as ManageUser[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          page,
          limit,
          bringTotal,
          total: res.total
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as ManageUser[],
          page,
          limit,
          bringTotal,
          total: 0
        }
      }
    }
)

export const GetUsersByStringThunk = createAsyncThunk(
  'manageUsersData/GetUsersByStringThunk',
  async ({ LastEvaluatedKey, stringToSearch, limit, bringTotal, page }: ExtraDataUser) => {
    try { 
      //console.log("params", LastEvaluatedKey, stringToSearch, limit, bringTotal, page)
      stringToSearch = stringToSearch ?? ""
      const res = await getUsersBySearch({ LastEvaluatedKey, limit, bringTotal, stringToSearch })
      res.results = res.results.map((user: ManageUser) => {
        return user
      })
      return {
        results: res.results as ManageUser[],
        LastEvaluatedKey: res.LastEvaluatedKey ?? "",
        page,
        limit,
        bringTotal,
        total: res.total,
        stringToSearch
      }
    } catch (error) {
      console.log("error", error)
      return {
        results: [] as ManageUser[],
        page,
        limit,
        bringTotal,
        total: 0,
        stringToSearch
      }
    }
  }
)

export const GetUserByIdThunk = createAsyncThunk(
  'manageUsersData/GetUserByIdThunk',
  async ({ id }: { id: string }) => {
    try { 
      const res = await getUser(id)
      return {
        user: res.user as ManageUser
      }
    } catch (error) {
      console.log("error", error)
      return {
        user: {} as ManageUser
      }
    }
  }
)

export const numberUsersByPage: number[] = [ 10, 25, 50, 100 ]

const initialState = {
    stringToSearch: "",
    total: 0,
    limit: numberUsersByPage[0],
    page: 1,
    usersIds: [] as string[],
    usersToShow: [] as ManageUser[],
    LastEvaluatedKey: "",
    loadingContainer: true,
    orderDirection: false,
    orderColumn: ""
}

export const manageUsersDataSlice = createSlice({
  name: 'manageUsersData',
  initialState,
  reducers: {

    removeDeletedUserReducer: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload

      state.usersIds = state.usersIds.filter((id: string) => id !== action.payload.id)
      state.usersToShow = state.usersToShow.filter((user: ManageUser) => user.id !== action.payload.id)

      if(id === state.LastEvaluatedKey && state.usersIds.length > 1){
        const position = state.usersIds.indexOf(id)
        state.LastEvaluatedKey = state.usersIds[position-1]
      }
      state.total = state.total - 1
      return state
    },
    removeMultipleDeletedUserReducer: (state, action: PayloadAction<{ usersIds: string[] }>) => {
      const { usersIds } = action.payload

      state.usersIds = state.usersIds.filter((id: string) => !usersIds.includes(id))
      state.usersToShow = state.usersToShow.filter((user: ManageUser) => !usersIds.includes(user.id) )
      if(usersIds.includes(state.LastEvaluatedKey) && state.usersIds.length > 1)
        state.LastEvaluatedKey = state.usersToShow[state.usersToShow.length - 1].id
      state.total = state.total - usersIds.length
      return state
    },
    updateEditedUsersReducer: (state, action: PayloadAction<{ newUsersInfo: ManageUser[] }>) => {
        const { newUsersInfo } = action.payload
        state.usersToShow.forEach((user: ManageUser) => {
            const newUserInfo = newUsersInfo.find((userInfo: ManageUser) => user.id === userInfo.id)
            if(newUserInfo)
                return newUserInfo
            return user
        })
        return state
      },
    updateRoleUsersReducer: (state, action: PayloadAction<{userId: string, roleId: string}>) => {
      const { userId, roleId } = action.payload
      state.usersToShow.forEach((user: ManageUser) => {
          if(user.id === userId)
              user.roleId = roleId
      })
      return state
    },
    orderManageUsersReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
        const { orderColumn, orderDirection  } = action.payload

        state.usersToShow = getOrderedState({ orderColumn, orderDirection, array: state.usersToShow })
        state.orderColumn = orderColumn
        state.orderDirection = orderDirection
        return state
    },
    resetManageUsersReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetUsersThunk.fulfilled, (state, action: PayloadAction<{ results: ManageUser[], LastEvaluatedKey?: string, page: number, limit: number, bringTotal: boolean, total: number }>) => {
      const { results,  page, LastEvaluatedKey, limit, bringTotal, total } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          state.usersIds[skip+i] = results[i].id
          lastIndex = skip+i
      }

        state.usersIds = state.usersIds.slice(0,lastIndex+1)

        state.usersToShow = results
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? ""
        state.limit = limit ?? 0
        state.total = total
        state.stringToSearch = ""
        return state
      }).addCase(GetUsersThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetUsersThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      }).addCase(GetUserByIdThunk.fulfilled, (state, action: PayloadAction<{ user: ManageUser }>) => {
        const { user } = action.payload
        if(user.id){
          state.usersToShow = new Array(user)
          state.usersIds = new Array(action.payload.user.id)
          state.total = 1
        }else{
          state.usersToShow = []
          state.usersIds = []
          state.total = 0
        }
        state.loadingContainer = false
        state.stringToSearch = ""
        return state
      }).addCase(GetUserByIdThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetUserByIdThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      }).addCase(GetUsersByStringThunk.fulfilled, (state, action: PayloadAction<{ results: ManageUser[], LastEvaluatedKey?: string, page: number, limit: number, bringTotal: boolean, total: number, stringToSearch?: string }>) => {
          const { results, page, LastEvaluatedKey, bringTotal, total, stringToSearch } = action.payload

          let lastIndex = 0
          for(let i=0; i < results.length; i++){
              const skip = state.limit * (page-1)
              state.usersIds[skip+i] = results[i].id
              lastIndex = skip+i
          }
    
          state.usersIds = state.usersIds.slice(0,lastIndex+1)

          state.usersToShow = results
          state.loadingContainer = false
          state.page = page
          state.LastEvaluatedKey = LastEvaluatedKey ?? ""
          state.total = total
          state.loadingContainer = false
          state.stringToSearch = stringToSearch ?? ""
          return state
      }).addCase(GetUsersByStringThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetUsersByStringThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
    removeDeletedUserReducer,
    resetManageUsersReducer,
    removeMultipleDeletedUserReducer,
    orderManageUsersReducer,
    updateEditedUsersReducer,
    updateRoleUsersReducer
} = manageUsersDataSlice.actions

export default manageUsersDataSlice.reducer