import React, { useEffect } from "react";
import "./SideBar.css"
import { sidebarOptionsGeneral, sidebarOptionsGeneralSuperAdmin } from '../../../utils/sidebarOptions/sidebarOptions'
import { ISideBarOption } from "../../../utils/sidebarOptions/sidebarOptions"
import SideBarOptionsList from '../SideBarOptionsList/SideBarOptionsList';
import LogoutButton from '../LogoutButton/LogoutButton';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import { GetProductsRequestedThunk } from "../../../redux/slices/components/Products/ProductsSlice";
import { getCertificateUsersImagesThunk } from "../../../redux/slices/components/VerifyCertificate/VerifyCertificateDataSlice";
import { getIdUsersImagesThunk } from "../../../redux/slices/components/VerifyId/VerifyIdDataSlice";
import { getProfileUsersImagesThunk } from "../../../redux/slices/components/VerifyPicture/verifyPictureDataSlice";
import { GetUsersReportsThunk } from "../../../redux/slices/components/ManageReports/manageReportsDataSlice";

const SideBar = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    
    const userPolicies = useSelector((state: RootState) => state.auth.user.roleData?.policies)
    const name = useSelector((state: RootState) => state.auth.user.roleData?.name)

    const products = useSelector((state: RootState) => state.products.products)
    const certificates = useSelector((state: RootState) => state.VerifyCertificateData.sliderData)
    const ids = useSelector((state: RootState) => state.verifyIdData.sliderData)
    const pictures = useSelector((state: RootState) => state.verifyPictureData.sliderData)
    const userReports = useSelector((state: RootState) => state.manageUserReports.total)

    // user.roleId 
    const userOptions: ISideBarOption[] = sidebarOptionsGeneral.filter((sideBar) => {
        let haveAccess = false
        sideBar.role.forEach((role) => {
            if(role.id_Category === "Access_Management")
                haveAccess = true

            const policy = userPolicies.find(p => 
                p.id_Category === role.id_Category &&
                p.id_Policy === role.id_Policy &&
                p.id_Action === role.id_Action
            )
            if(policy)
                haveAccess = true
        })
        return haveAccess
    })
    const adminOptions: ISideBarOption[] = sidebarOptionsGeneralSuperAdmin.filter((sideBar) => {
        let haveAccess = false
        sideBar.role.forEach((role) => {
            const policy = userPolicies.find(p => 
                p.id_Category === role.id_Category &&
                p.id_Policy === role.id_Policy &&
                p.id_Action === role.id_Action
            )
            if(policy)
                haveAccess = true
        })
        return haveAccess
    }).map((sideBar) => {
        let numberNotifications = undefined
        for(const role of sideBar.role){
            if(role.id_Category === "Verify" && role.id_Policy === "Products" && role.id_Action === "Display" ){
                numberNotifications = products.length
            }
            if(role.id_Category === "Verify" && role.id_Policy === "Certificate" && role.id_Action === "Display" ){
                numberNotifications = certificates.length
            }
            if(role.id_Category === "Verify" && role.id_Policy === "Id" && role.id_Action === "Display" ){
                numberNotifications = ids.length
            }
            if(role.id_Category === "Verify" && role.id_Policy === "Profile_Picture" && role.id_Action === "Display" ){
                numberNotifications = pictures.length
            }
            if(role.id_Category === "Manage_Reports" && role.id_Policy === "Reports" && role.id_Action === "Display" ){
                numberNotifications = userReports
            }
        }

        return {
            ...sideBar,
            numberNotifications
        }
    })


    useEffect(() => {
        // { id_Category: "Verify", id_Policy: "Products", id_Action: "Display" })
        for(const option of adminOptions){
            for(const role of option.role){
                if(role.id_Category === "Verify" && role.id_Policy === "Products" && role.id_Action === "Display" ){
                    dispatch( GetProductsRequestedThunk() )
                }
                if(role.id_Category === "Verify" && role.id_Policy === "Certificate" && role.id_Action === "Display" ){
                    dispatch( getCertificateUsersImagesThunk() )
                }
                if(role.id_Category === "Verify" && role.id_Policy === "Id" && role.id_Action === "Display" ){
                    dispatch( getIdUsersImagesThunk() )
                }
                if(role.id_Category === "Verify" && role.id_Policy === "Profile_Picture" && role.id_Action === "Display" ){
                    dispatch( getProfileUsersImagesThunk() )
                }
                if(role.id_Category === "Manage_Reports" && role.id_Policy === "Reports" && role.id_Action === "Display" ){
                    dispatch( GetUsersReportsThunk({ page: 1, limit: 10, bringTotal: true, gimGimmerAll: "All" }) )
                }
            }
            
        }
  
    }, [])
    

    return (
        <div className="sidebar">
            <div className="container-user-options">
                <SideBarOptionsList sideBarOptionsList={userOptions} />
            </div>

            {
                adminOptions.length > 0 &&
                <div className="container-admin-options">
                    <p className="super-admin-text">- {name.replace(/_/g, " ")} -</p>
                    <SideBarOptionsList  sideBarOptionsList={adminOptions} />
                </div>
            }


            <div className="container-logout-option">
              <LogoutButton />
            </div>
        </div>
    )
}

export default React.memo( SideBar )