import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import "./TransactionReportRow.css"
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateBugReportSeenWeb } from "../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions";
import { TransactionBugReportTableItem, TransactionTypeReport, updateSeenTransactionBugReportReducer } from "../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice";
import TransactionReportRow from "./TransactionReportRow/TransactionReportRow";
import { updateBillingReportSeenWeb, updatePaymentReportSeenWeb } from "../../../utils/requestsUtils/functionsRequests/reports/transactions/transactionFunctions";

const TransactionReportRows = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const bugReportsToShow = useSelector((state: RootState) => state.transactionReports.transactionReportsToShow);
    const type = useSelector((state: RootState) => state.transactionReports.type);
    const loadingContainer = useSelector((state: RootState) => state.transactionReports.loadingContainer);

    const clickOnOffer = useCallback(
      (id: string, type: TransactionTypeReport) => {
          //dispatch( setDetailedBugReportReducer({ bugReport }) )
          navigate(`/transactions-bugs/${id}?type=${type}`)
          const request = type === "Billing" ? updateBillingReportSeenWeb : updatePaymentReportSeenWeb
          
          request( id )
          .then(() => {
              dispatch( updateSeenTransactionBugReportReducer({ id: id}) )
          }).catch((error) => {
              console.log("error", error)
          })
              
          //dispatch(setDetailedOfferReducer({bugReport, imgUrl: userImg}))
      },
        [type],
    )
    

    return (
      <div className="container-bug-reports-rows flex flex-1 flex-d-column ff-roboto">
        <div className="aux-scroll-bug-reports">

              { loadingContainer && <LoadingSpinner fullPage={false} /> }
              
              {
                  
                  bugReportsToShow.map((report: TransactionBugReportTableItem, index: number) => {
                      return(
                        <TransactionReportRow
                            key={report.id}
                            transactionReport={report}
                            //index={index}
                            onPress={clickOnOffer}
                        />
                      )
                  })
                  
              }

          </div>
      </div>
    )
}

export default TransactionReportRows