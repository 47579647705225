
import { LastEvaluatedKeyBugReports } from "../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice"
import { PriorityReport, StatusReportBug, StatusTransactionReport, TransactionReport } from "../../../../dbModels"
import { 
    getUserCredentials,
    adminApiUrl
} from "../../../axiosUtils"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { FileInfo } from "../files/filesFunctions"
import { TransactionTypeReport } from "../../../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice"

type GetTransactionReportsBody = {
    LastEvaluatedKey?: string,
    limit: number,
    bringTotal: boolean
    status?: StatusTransactionReport
}

export const getBillingReports = (body: GetTransactionReportsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: body
            };
            axios.get(`${adminApiUrl}/v1/reports/billing`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/billing`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getPaymentReports = (body: GetTransactionReportsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: body
            };
            axios.get(`${adminApiUrl}/v1/reports/payment`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/payment`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getBillingReportById = (id: string) => {
    return new Promise((resolve: (value: TransactionReport) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/reports/billing/${id}`, config)
            .then((res) => {resolve(res.data.item)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/billing/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getPaymentReportById = (id: string) => {
    return new Promise((resolve: (value: TransactionReport) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/reports/payment/${id}`, config)
            .then((res) => {resolve(res.data.item)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/payment/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



export const updateBillingReportSeenWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/billing/${id}/seen`, { }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/billing/${id}/seen`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const updatePaymentReportSeenWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/payment/${id}/seen`, { }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/payment/${id}/seen`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

const videoSuffixes = ["mp4", "mov"]

export const getFilesTransactionReport = (id: string, type: TransactionTypeReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { id }
            };
            const path = type === "Billing" ? "billing" : "payment"
            axios.get(`${adminApiUrl}/v1/reports/${path}/${id}/files`, config)
            .then((res) => { 
                let countVideos = 0
                let countImages = 0
                const urlsFiles = res.data.links
                const filesInfo: FileInfo[] = urlsFiles.map((url: string) => {
                    const urlArray = url.split(".")
                    const suffix = urlArray[urlArray.length - 1]
                    if(videoSuffixes.includes(suffix)){
                        countVideos++
                        const counter = countVideos.toString()
                        return {
                            url,
                            type: "video",
                            suffix: suffix,
                            numberFile: counter.length === 1 ? "0"+counter : counter
                        }
                    }else{
                        countImages++
                        const counter = countImages.toString()
                        return {
                            url,
                            type: "image",
                            suffix: suffix,
                            numberFile: counter.length === 1 ? "0"+counter : counter
                        }
                    }
                })
    
                resolve(filesInfo)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/${path}/${id}/files`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateTransactionReportStatusWeb = (id: string, status: StatusTransactionReport, type: TransactionTypeReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            const path = type === "Billing" ? "billing" : "payment"
            axios.patch(`${adminApiUrl}/v1/reports/${path}/${id}/status`, { status }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/${path}/${id}/status`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 