import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowProductProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}

const RowProduct = ({ inputValues, isDay, valueCheckBox, onChangeCheckbox, onChange, onFocus }: RowProductProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "productGimIVA_Day",        placeholder: "Gim IVA",         width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "productGimmerIVA_Day",     placeholder: "Gimmer IVA",      width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "productGimworkIVA_Day",    placeholder: "Gimwork IVA",     width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "productRMPC_Day",          placeholder: "RMP",             width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "productGimIVA_Night",          placeholder: "Gim IVA",         width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "productGimmerIVA_Night",       placeholder: "Gimmer IVA",      width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "productGimworkIVA_Night",      placeholder: "Gimwork IVA",     width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "productRMPC_Night",            placeholder: "RMP",             width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
    ], []);
    

    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#F6F3FE"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_11")}
                color="#F6F3FE"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowProduct