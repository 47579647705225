import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import "./ButtonsTransactionReport.css"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useCheckAccess from '../../../../hooks/useCheckAccess/useCheckAccess'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../LoadingSpinner'
import { updateTransactionReportStatusWeb } from '../../../../../utils/requestsUtils/functionsRequests/reports/transactions/transactionFunctions'
import { setNewStatusTransactionBugReportReducer, updateStatusTransactionBugReportReducer } from '../../../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'
import { StatusTransactionReport } from '../../../../../utils/dbModels'

const ButtonsTransactionReport = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const myUserId = useSelector((state: RootState) => state.auth.user.id)

    const idReport = useSelector((state: RootState) => state.transactionReports.transactionReport.id)
    const type = useSelector((state: RootState) => state.transactionReports.transactionReport.type)
    const status = useSelector((state: RootState) => state.transactionReports.transactionReport.status)

    const accessEdit = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Transaction_Reports", id_Action: "Edit" })

    const handleRefuse = () => {
        handle("CLOSED")
    }

    const handleAccept = () => {
        handle("PENDING")
    }

    const handleClose = () => {
        handle("CLOSED")
    }

    const handle = (newStatus: StatusTransactionReport) => {
        setLoading(true)
        updateTransactionReportStatusWeb(idReport, newStatus, type)
        .then(() => {

            dispatch( setNewStatusTransactionBugReportReducer({ newStatus }) )
            dispatch( updateStatusTransactionBugReportReducer({ id: idReport, status: newStatus }) )
            /*
            if(value === "ACCEPTED")
                dispatch( addHistoryItemBugReport({ userId: myUserId, date: new Date().toISOString(), event: "ACCEPT" }) )
            else
                navigate(-1)
            */
            //dispatch( setEndDateIncidentBugReportReducer() )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }


    return (
        <div className="flex con-buttons-bug-reports p-relative width-100">

            {
                accessEdit && status === "OPENED" &&
                <button 
                    onClick={handleRefuse} 
                    className="btn-move-bug-report ban-btn fw-600 fs-13"
                >
                    {"Refuse"}
                </button>
            }

            {
                accessEdit && status === "OPENED" &&
                <button 
                    onClick={handleAccept} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {"Accept"}
                </button>
            }

            {
                accessEdit && status === "PENDING" &&
                <button 
                    onClick={handleClose} 
                    className="btn-move-bug-report ban-btn fw-600 fs-13"
                >
                    {"Closed"}
                </button>
            }
            
            { loading && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default React.memo( ButtonsTransactionReport )