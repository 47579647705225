import React, { useTransition } from 'react'
import RowPolicyRole from './RowPolicyRole/RowPolicyRole'
import { useTranslation } from 'react-i18next'
import "./RowCategoryRole.css"
import { Data_Policy, Data_Rol_Table } from '../../../../../redux/slices/components/ManageRoles/manageRolesSlice'

type RowCategoryRoleProps = {
    category: Data_Rol_Table
    enableEdit: boolean
}

const RowCategoryRole = React.memo(({
  category,
  enableEdit
}: RowCategoryRoleProps) => {
  const { t } = useTranslation()
    return (
        <div>
            <div className="flex flex-ai-center flex-jc-sb row-category-role pl-18 pr-18">
                <p className="fs-14 fw-500">
                  {category.id_Category}
                </p>
                <div className="flex width-actions">
                    {
                      category.actions.map((action: string) => {
                        return(
                          <div key={action} className="flex flex-jc-c width-25">
                              <p className="fs-14">
                                  { t(`Roles:${action}`) }
                              </p>
                          </div>
                        )
                      })
                    }
                </div>
            </div>
            <div className="pb-13 pt-13">
              {
                category.policies.map((policy: Data_Policy) => {
                    return (
                        <RowPolicyRole 
                          key={policy.id_Policy}
                          id_Category={category.id_Category}
                          policy={policy}
                          actionsAllowed={category.actions}
                          enableEdit={enableEdit}
                      />
                    )
                })
              }
            </div>
        </div>
    )
})

export default RowCategoryRole