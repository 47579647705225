import React, { useState } from 'react'
import "./FooterLogin.css"
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const FooterLogin = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [logosSocialMedia] = useState([
        { icon: <FaFacebookSquare className="fs-22" />, link: "https://www.google.com/" },
        { icon: <FaYoutube className="fs-22" />,        link: "https://www.youtube.com/channel/UCChkGMMruHoC_bFGgwekedA" },
        { icon: <FaLinkedin className="fs-22" />,       link: "https://www.linkedin.com/company/gimwork/" },
        { icon: <FaInstagram className="fs-22" />,      link: "https://www.google.com/" }
    ])

    const goToScreen = (screen: string) => {
        window.location.href= `https://gimwork.com/${screen}`;
    }

    const goToInternalScreen = (screen: string) => {
        navigate(`/${screen}`)
    }

    return (
        <footer className="footer-container center">

            <div className="cotainer-login width-100">

                <div className="flex-row-column flex-jc-sb">
                    <a href="https://www.gimwork.com">
                        <img className="navbar-logo" src={"./assets/images/LOGO.svg"} alt="Logo" />
                    </a>

                    <div className="flex flex-ai-center con-icons-footer">
                        {
                            logosSocialMedia.map((logo, index) => {

                                return(
                                    <a key={index} href={logo.link} className="item-logo-footer center" target="_blank">
                                        {logo.icon}
                                    </a>
                                )
                            })
                        }
                </div>
                </div>


                
                <div className="flex mt-40">

                    <a href="https://play.google.com/" target="_blank">
                        <img
                            className="store-logo pointer"
                            src="https://www.gimwork.com/wp-content/uploads/2023/07/google-play-badge.png" 
                            alt="app sotre logo"
                        />
                    </a>

                    <a href="https://www.apple.com/app-store" target="_blank">
                        <img
                            className="store-logo ml-10 pointer"
                            src="https://www.gimwork.com/wp-content/uploads/2023/07/Download_on_the_App_Store_Badge_US-UK.png" 
                            alt="app sotre logo"
                        />
                    </a>

                </div>

                <div className="flex-row-column flex-jc-sb mt-50">
                    <p className="rights-reserved-footer">
                        {t("General:label_219")}
                    </p>

                    <div className="flex-row-column container-extra-links-footer">
                        <button className="pointer" onClick={() => goToInternalScreen("privacy-policy")}>
                            <p className="extra-links-footer">{t("General:label_233")}</p>
                        </button>
                        <button className="pointer" onClick={() => goToInternalScreen("legal-notice")}>
                            <p className="extra-links-footer">{t("General:label_235")}</p>
                        </button>
                        {
                            /*
                                <button>
                                    <p className="extra-links-footer">{t("General:label_234")}</p>
                                </button>
                            */
                        }
                        <button className="pointer" onClick={() => goToInternalScreen("terms-and-conditions")}>
                            <p className="extra-links-footer">{t("General:label_221")}</p>
                        </button>
                    </div>
                </div>

            </div>

        </footer>
    )
}

export default FooterLogin