

export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}

export const getPlatform = () => {
    var platform = ["Win32", "Android", "iOS"];
 
    for (var i = 0; i < platform.length; i++) {
 
        if (navigator.platform.indexOf(platform[i]) >- 1) {
 
            return platform[i];
        }
    }
 }


export const getMobileOperatingSystem2 = () => {
    const userAgent = navigator.userAgent;

    // Huawei Phone must come first because its UA also contains "Android"
    if (userAgent.includes("HUAWEI")) {
        return "HUAWEI"
    } else if (userAgent.includes("Android")) {
        return "ANDROID"
    } else if (userAgent.includes("iPhone")) {
        return "IOS"
    } else {
        return "ANOTHER"
    }
}
