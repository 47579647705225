import React, { ChangeEvent, useEffect, useState } from 'react'
import "./SliderProductCard.css"
import { getProfilePictureByUserId } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { useAppDispatch } from '../../../../redux/store/store'
import { useTranslation } from 'react-i18next'
import ButtonsValidateProduct from './ButtonsValidateProduct/ButtonsValidateProduct'
import ButtonsSubmitFormProduct from './ButtonsSubmitFormProduct/ButtonsSubmitFormProduct'
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../utils/verifications/Forms'
import { MyYup } from '../../../../utils/verifications/myYup'
import GeneralInputTitle from '../../../UIcomponents/GeneralInputTitle/GeneralInputTitle'
import { getMinimalUserInfoData, S3DomainGimwork_public_files, UserInfoCache, UserInfoCacheType } from '../../../../utils/staticVariables/staticVariables'
import { ProductDB, removeProductReducer } from '../../../../redux/slices/components/Products/ProductsSlice'
import { ProductUpdateBody, updtateProductDBVerification } from '../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'
import MyCheckbox from '../../../UIcomponents/MyCheckbox/MyCheckbox'
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName'
import LoadingSpinner from '../../../LoadingSpinner'

type SliderProductCardProps = {
    product: ProductDB,
    index: number,
    lengthProduct: number
    editable: boolean
}

const SliderProductCard = ({ product, index, editable }: SliderProductCardProps) => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const jobName = useGetJobName(product.jobId)

    const [profileImgeUrl, setProfileImgeUrl] = useState<string>("")
    const [showForm, setShowForm] = useState(false)
    const [userName, setUserName] = useState("")
    const [pdfCheckbox, setPdfCheckbox] = useState(false)
    const [zipCheckbox, setZipCheckbox] = useState(false)
    const [errorCheckbox, setErrorCheckbox] = useState(false)

    const [loading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: product.productName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justValidCharacters()},
        RFCcode: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
        ISR: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMMER: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMWORK: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        RMPC: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
    })


    useEffect(() => {

        const getData = () => {
            const user = UserInfoCache.userNameCache.get(product.userId)

            if(user?.name){
                    setUserName(user.name)
                }else{
                getMinimalUserInfoData(product.userId)
                .then((userInfo: UserInfoCacheType) => {
                    UserInfoCache.addNameInfo(product.userId, { name: userInfo.name, phoneNumber: userInfo.phoneNumber, mail: userInfo.mail, gimmerScore: userInfo.gimmerScore, gimScore: userInfo.gimScore })
                    setUserName(userInfo.name)
                }).catch(() => {})
            }

            getProfilePictureByUserId(product.userId, "small")
            .then((url) => {
                setProfileImgeUrl(url)
            }).catch(() => {
    
            })
            
        }

        getData()

    }, [])



    const getFilesArray = () => {
        const files = []
        if(pdfCheckbox) files.push("pdf")
        if(zipCheckbox) files.push("zip")

        return files
    }


    const onPressValidationButtons = async(value: boolean) => {
        
        try {
            if(value){
                setShowForm(true)
            }else{
                setLoading(true)
                /*
                if(product.typeProduct === "DIGITAL"){
                    const filesTypes = getFilesArray()
                    await updtateProductDBVerification(product.id, { status: "REJECTED" })
                    dispatch( removeProductReducer(product.id) )
                }else{
                    const body: ProductUpdateBody = {
                        productName: inputValues["productName"].value,
                        RFCcode: value ? inputValues["RFCcode"].value : "null",
                        ISR: value ? Number(inputValues["ISR"].value) : -1,
                        IVA_GIMMER: value ? Number(inputValues["IVA_GIMMER"].value) : -1,
                        IVA_GIMWORK: value ? Number(inputValues["IVA_GIMWORK"].value) : -1,
                        RMPC: value ? Number(inputValues["RMPC"].value) : -1,
                        status: "REJECTED",
                        typeProduct: "PHYSICAL"
                    }
                    await updtateProductDBVerification(product.id, body)
                    dispatch( removeProductReducer(product.id) )
                }*/
                await updtateProductDBVerification(product.id, { status: "REJECTED" })
                dispatch( removeProductReducer(product.id) )
    
            }
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
        }

            
    }

    const onPressSubmitButtons = async(value: boolean) => {
        try {
            if(value){
                let existsError = false;
                for(const nameInput in inputValues){
                    if(checkErrorsWhenSubmit(nameInput, inputValues, true))
                        existsError = true
                }
                setInputValues({...inputValues})
    
                if(product.typeProduct !== "PHYSICAL" && !pdfCheckbox && !zipCheckbox){
                    setErrorCheckbox(true)
                    return
                }
        
                if(existsError)
                    return
    
                setLoading(true)
                
                if(product.typeProduct === "DIGITAL"){
                    const filesTypes = getFilesArray()
                    const body: ProductUpdateBody = {
                        productName: inputValues["productName"].value,
                        RFCcode: inputValues["RFCcode"].value,
                        ISR: Number(inputValues["ISR"].value),
                        IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                        IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                        RMPC: Number(inputValues["RMPC"].value),
                        status: "VALIDATED",
                        typeProduct: product.typeProduct,
                        fileType: filesTypes
                    }
                    await updtateProductDBVerification(product.id, body)
                    dispatch( removeProductReducer(product.id) )
                }else{
                    const body: ProductUpdateBody = {
                        productName: inputValues["productName"].value,
                        RFCcode: inputValues["RFCcode"].value,
                        ISR: Number(inputValues["ISR"].value),
                        IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                        IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                        RMPC: Number(inputValues["RMPC"].value),
                        status: "VALIDATED",
                        typeProduct: product.typeProduct,
                    }
                    await updtateProductDBVerification(product.id, body)
                    dispatch( removeProductReducer(product.id) )
                }
                
            }else{
                setShowForm(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }

    return (
        <div id={`slider-product-${index.toString()}`} className="con-product-img-card p-relative" >

            <div className="card-product-img flex flex-d-column">

                <div className="flex flex-1">
                    
                    <div className="width-50 height-100">
                        {
                            product.typeProduct === "PHYSICAL" ? 
                            <img 
                                src={`${S3DomainGimwork_public_files}/${product.userId}/${product.id}/physical_img`}
                                alt="" 
                                style={{ width: "100%", height: "30vh", objectFit: "cover" }}
                            />
                            :
                            <div className="border-color-text-dashed center" style={{ width: "100%", height: "100%"}}>
                                <p className="fs-36-vh fw-700">{"File"}</p>
                            </div>
                        }
                    </div>

                    {
                        showForm ?
                        <div className="flex flex-d-column flex-1 pl-20">
                            <GeneralInputTitle 
                                title={`Product name`}
                                name={"productName"}
                                value={inputValues["productName"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={"Product name"}
                                error={inputValues["productName"].error}
                                width="100%"
                            />

                            <div className="flex flex-jc-sb mt-10">
                                <GeneralInputTitle 
                                    title={`RFC Code`}
                                    name={"RFCcode"}
                                    value={inputValues["RFCcode"].value}
                                    onChange={onChangeValueInput}
                                    onFocus={onChangeFocusInput}
                                    placeholder={"RFC Code"}
                                    error={inputValues["RFCcode"].error}
                                    width="70%"
                                />
                                <GeneralInputTitle 
                                    title={`ISR`}
                                    name={"ISR"}
                                    value={inputValues["ISR"].value}
                                    onChange={onChangeValueInput}
                                    onFocus={onChangeFocusInput}
                                    placeholder={"ISR"}
                                    error={inputValues["ISR"].error}
                                    width="26%"
                                />
                            </div>

                            <div className="flex flex-jc-sb mt-10">
                                <GeneralInputTitle 
                                    title={`IVA GIMMER`}
                                    name={"IVA_GIMMER"}
                                    value={inputValues["IVA_GIMMER"].value}
                                    onChange={onChangeValueInput}
                                    onFocus={onChangeFocusInput}
                                    placeholder={"IVA GIMMER"}
                                    error={inputValues["IVA_GIMMER"].error}
                                    width="48%"
                                />
                                <GeneralInputTitle 
                                    title={`IVA GIMWORK`}
                                    name={"IVA_GIMWORK"}
                                    value={inputValues["IVA_GIMWORK"].value}
                                    onChange={onChangeValueInput}
                                    onFocus={onChangeFocusInput}
                                    placeholder={"IVA GIMWORK"}
                                    error={inputValues["IVA_GIMWORK"].error}
                                    width="48%"
                                />
                            </div>

                            <div className="flex flex-jc-sb mt-10">
                                <GeneralInputTitle 
                                    title={`RMPC`}
                                    name={"RMPC"}
                                    value={inputValues["RMPC"].value}
                                    onChange={onChangeValueInput}
                                    onFocus={onChangeFocusInput}
                                    placeholder={"RMPC"}
                                    error={inputValues["RMPC"].error}
                                    width="48%"
                                />
                            </div>

                            {
     
                                   
                                <div className="">
                                    <p className="fs-16-vh fw-600">{"Country: México"}</p>
                                    <p className="fs-18-vh fw-600 mt-10">{jobName}</p>
                                </div>
                            }
                        </div>
                        : 
                        <div className="width-50 flex flex-d-column flex-jc-c pl-20">
                            <p className="fs-18-vh fw-600">{product.productName}</p>
                            <p className="fs-16-vh mt-6">{jobName}</p>
                            <p className="fs-16-vh mt-10">{product.description}</p>
                        </div>
                    }

                </div>

                <div className="flex flex-ai-center mt-14" style={{ height: "8.5vh" }}>

                    {
                        showForm ? 
                        <div className="flex-1 p-relative">
                            {
                                product.typeProduct === "DIGITAL" &&
                                <div className="flex flex-ai-center width-100 flex-jc-sb con-filt-types">
                                    <div className="flex flex-ai-center">
                                        <MyCheckbox size={"1.3rem"} value={pdfCheckbox} onChange={() => { setPdfCheckbox(!pdfCheckbox); setErrorCheckbox(false) }} />
                                        <img 
                                            id="globe-btn"
                                            src="/assets/svg/pdf.svg" 
                                            alt="globe" 
                                            style={{ height: "3.8vh" }}
                                            className="ml-10"
                                        />
                                    </div>

                                    <div className="flex flex-ai-center">
                                        <MyCheckbox size={"1.3rem"} value={zipCheckbox} onChange={() => {setZipCheckbox(!zipCheckbox); setErrorCheckbox(false)}} />
                                        <img 
                                            id="globe-btn"
                                            src="/assets/svg/zip.svg" 
                                            alt="globe" 
                                            style={{ height: "3.8vh" }}
                                            className="ml-10"
                                        />
                                    </div>
                                </div>
                            }
                            {errorCheckbox && <p className="fs-12-vh color-error p-absolute mt-6">{"You must select one or two of these options"}</p>}
                        </div>
                        : 
                        <div className="flex flex-ai-center width-50">
                            <img src={profileImgeUrl} className="circle" style={{ width: "5.5vh", height: "5.5vh" }} alt="user" />  
                            <div className="ml-10">
                                <p className="fs-18-vh fw-600 mr-10">{userName}</p>
                                <p className="fs-16-vh mt-4">{"From: México"}</p>
                            </div>
                        </div>
                    }

                    <div className="flex width-50 pl-20">

                    {
                        editable &&
  
                        (showForm 
                        ? <ButtonsSubmitFormProduct onPress={onPressSubmitButtons} />
                        : <ButtonsValidateProduct onPress={onPressValidationButtons} />
                        )  

                    }

                    </div>
                </div>

            </div>
            
            { loading && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default React.memo( SliderProductCard )