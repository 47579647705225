import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllowedRoles } from '../../../../utils/requestsUtils/functionsRequests/roles/roles'
import { AllowedRolesResponse, Policy, Role } from '../ManageRoles/manageRolesSlice'


export type DeleteUserModalData = {
  id: string,
  firstName: string,
  lastName: string
}

export type EditUserRoleModalData = {
    id: string,
    firstName: string,
    lastName: string,
    role: string
}

export type SendMessageUserModalData = {
    id: string,
    firstName: string,
    lastName: string,
    mail: string
}
  
export type ManageUsersModalsState = {
  modalName: string,
  deleteUserModal: DeleteUserModalData,
  editUserRoleModal: EditUserRoleModalData,
  sendMessageUserModal: SendMessageUserModalData,
}

export const getAvailableRolesChunk = createAsyncThunk(
  'manageRoles/getAvailableRolesChunk',
  async (thunkAPI) => {
      return getAllowedRoles()
      .then((res: AllowedRolesResponse) => {
          return res
      })
      .catch(() => {
          return { 
              policies: [] as Policy[],
              roles: [] as Role[]
          }
      })
  }
)

const initialState = {
  modalName: "",
  deleteUserModal: {} as DeleteUserModalData,
  editUserRoleModal: {} as EditUserRoleModalData,
  sendMessageUserModalData: {} as SendMessageUserModalData,
  SendNotificationModal: {} as any,
  roles: [] as Role[]
};

export const manageUsersModalsSlice = createSlice({
  name: 'manageUsersModals',
  initialState,
  reducers: {
    setDeleteUserModalStateReducer: (state, action: PayloadAction<DeleteUserModalData>) => {
      state.modalName = "DeleteUserModal"
      state.deleteUserModal = action.payload
      return state;
    },
    setEditUserRoleModalStateReducer: (state, action: PayloadAction<EditUserRoleModalData>) => {
        state.modalName = "EditUserRoleModal"
        state.editUserRoleModal = action.payload
        return state;
    },
    setSendMessageUserModalStateReducer: (state, action: PayloadAction<SendMessageUserModalData>) => {
        state.modalName = "SendMessageUserModal"
        state.sendMessageUserModalData = action.payload
        return state;
    },
    setDeleteMultipleUserModalStateReducer: (state) => {
        state.modalName = "DeleteMultipleUserModal"
        return state;
      },
    setEditMultipleUserRoleModalStateReducer: (state) => {
        state.modalName = "EditMultipleUserRoleModal"
        return state;
    },
    setSendNotificationModalStateReducer: (state) => {
        state.modalName = "SendNotificationModal"
        return state;
    },
    resetManageUsersModalsReducer: (state) => {
      return {
        ...initialState,
        roles: state.roles
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableRolesChunk.fulfilled, (state, action: PayloadAction<AllowedRolesResponse>) => {
        state.roles = action.payload.roles
        return state
    })
    .addCase(getAvailableRolesChunk.pending, (state, action: any) => {
        return state
    }).addCase(getAvailableRolesChunk.rejected, (state, action: any) => {
        return state
    })
  }
});

// Action creators are generated for each case reducer function
export const { 
    setDeleteUserModalStateReducer, 
    setEditUserRoleModalStateReducer,
    setSendMessageUserModalStateReducer,
    setDeleteMultipleUserModalStateReducer,
    setEditMultipleUserRoleModalStateReducer,
    resetManageUsersModalsReducer,
    setSendNotificationModalStateReducer
} = manageUsersModalsSlice.actions

export default manageUsersModalsSlice.reducer