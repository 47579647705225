import React, { useRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GetUserByIdThunk, GetUsersByStringThunk, GetUsersThunk } from '../../../redux/slices/components/ManageUsers/manageUsersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { isUUID } from '../../../utils/verifications/uuid/uuid'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { setSendNotificationModalStateReducer } from '../../../redux/slices/components/ManageUsers/manageUsersModalsSlice'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'

const HeaderManageUsers = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const resetSearch = useRef<any>()

    const accessEdit = useCheckAccess({ id_Category: "Manage_Users", id_Policy: "Notification", id_Action: "Edit" })

    const limit =useSelector((state:RootState) => state.manageUsersData.limit)

    const submitSearchBar = (searchString: string) => {
        if(isUUID(searchString)){
            //dispatch(GetUserByIdThunk({ id: searchString }))
        }else{
            dispatch(GetUsersByStringThunk({ page: 1, limit,  bringTotal: true, stringToSearch: searchString }))
        }
    }
  
    const searchSubmit = useCallback(
      (searchString: string) => {
        submitSearchBar(searchString)
    },[],)

    const resetSearchBar = useCallback(
      () => {
        dispatch(GetUsersThunk({ page: 1, limit, bringTotal: true}))
    },[],)

    const handleSendInformation = useCallback(
        () => {
            dispatch( setSendNotificationModalStateReducer() )
        },
        [],
    )
    

    return (
      <div className="flex flex-ai-center con-header-offers">
            <p className="title-screen">{t("General:label_132")}</p>
            <SearcherBar style={{ width: "28rem" }} 
                ref={resetSearch} 
                searchSubmit={searchSubmit} 
                placeholder={t("General:label_133")} 
                addReset={true}
                resetSearchBar={resetSearchBar}
            />
            {
                accessEdit &&
                <button 
                    className="btn-request-product border-color-text border-radius-6 flex flex-ai-center ml-20 pl-12 pr-12 pointer"
                    onClick={handleSendInformation}
                >
                    <p className="fs-16-vh color-text">{t("General:label_275")}</p>
                </button> 
            }
      </div>
    )
}

export default React.memo( HeaderManageUsers )