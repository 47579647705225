import React from 'react'
import "./Icon.css"
import { 
    FaUserAlt, 
    FaUserCog,
     FaUsers, 
     FaIdCard, 
     FaBug,
     FaBalanceScale,
     FaBars,
     FaBriefcase,
     FaStar,
     FaListAlt,
     FaPowerOff, 
     FaCheckCircle,
     FaComments,
     FaTh,
     FaCertificate,
     FaTimesCircle,
     FaPen,
     FaSearch,
     FaTrashAlt,
     FaHospitalAlt,
     FaCaretDown,
     FaCaretUp,
     FaCheck,
     FaTimes,
     FaEllipsisV,
     FaFlag
    } from "react-icons/fa";

import { BsPersonSquare } from "react-icons/bs";
import { 
    AiOutlinePlus, 
    AiOutlineArrowUp,
    AiOutlineArrowDown, 
} from "react-icons/ai";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faScrollOld, faWallet } from '@fortawesome/pro-solid-svg-icons';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface IconProps {
    name: string,
    cssClass?: string
}

const Icon = ({name, cssClass = ""}: IconProps) => {

    switch (name) {
        case "FaUserAlt":
            return ( <FaUserAlt className={cssClass}/> )
        case "FaUserCog":
            return ( <FaUserCog className={cssClass}/> )
        case "FaUsers":
            return ( <FaUsers className={cssClass}/> )
        case "FaIdCard":
            return ( <FaIdCard className={cssClass}/> )
        case "FaBug":
            return ( <FaBug className={cssClass}/> )
        case "FaBalanceScale":
            return ( <FaBalanceScale className={cssClass}/> )    
        case "FaBriefcase":
            return ( <FaBriefcase className={cssClass}/> )
        case "FaListAlt":
            return ( <FaListAlt className={cssClass}/> )
        case "FaComments":
            return ( <FaComments className={cssClass}/> )
        case "FaTh":
            return ( <FaTh className={cssClass}/> )
        case "BsPersonSquare":
            return ( <BsPersonSquare className={cssClass}/> )
        case "FaCertificate":
            return ( <FaCertificate className={cssClass}/> )
        case "FaBars": // hamburguer menu
            return ( <FaBars className={cssClass}/> )   
        case "FaStar": // Star
            return ( <FaStar className={cssClass}/> )   
        case "FaPowerOff": // turn off
            return ( <FaPowerOff className={cssClass}/> )   
        case "FaCheckCircle": // Checked circle
            return ( <FaCheckCircle className={cssClass}/> ) 
        case "FaCheck": // Checked
            return ( <FaCheck className={cssClass}/> ) 
        case "FaTimesCircle": // Checked fail circle
            return ( <FaTimesCircle className={cssClass}/> ) 
        case "FaTimes": // Checked fail circle
            return ( <FaTimes className={cssClass}/> ) 
        case "FaPen": // pen
            return ( <FaPen className={cssClass}/> ) 
        case "AiOutlinePlus": // +
            return ( <AiOutlinePlus className={cssClass}/> ) 
        case "FaSearch": // pen
            return ( <FaSearch className={cssClass}/> ) 
        case "FaTrashAlt": // trash
            return ( <FaTrashAlt className={cssClass}/> ) 
        case "FaHospitalAlt": // FaHospitalAltFaCaretDown
            return ( <FaHospitalAlt className={cssClass}/> ) 
        case "FaCaretDown": // FaCaretDown
            return ( <FaCaretDown className={cssClass}/> ) 
        case "FaCaretUp": // FaCaretDown
            return ( <FaCaretUp className={cssClass}/> ) 
        case "FaEllipsisV": // FaCaretDown
            return ( <FaEllipsisV className={cssClass}/> ) 
        case "AiOutlineArrowUp": // 
            return ( <AiOutlineArrowUp className={cssClass}/> ) 
        case "AiOutlineArrowDown": // 
            return ( <AiOutlineArrowDown className={cssClass}/> ) 
        case "FaFlag": // 
            return ( <FaFlag className={cssClass}/> ) 
        case "FaWallet": // 
            return ( <FontAwesomeIcon icon={faWallet} className={cssClass} /> ) 
        case "FaGlobe": // 
            return ( <FontAwesomeIcon icon={faGlobe} className={cssClass} /> ) 
        case "FaScrollOld": // 
            return ( <FontAwesomeIcon icon={regular("scroll-old")} className={cssClass} /> ) 
        case "FaShop": // 
            return ( <FontAwesomeIcon icon={solid("shop")} className={cssClass} /> ) 
        case "TransactionBug":
            return ( <img src="../assets/svg/transaction_bug.svg" alt="transaction bug" /> ) 
        case "Form":
            return ( <img src="../assets/svg/Form.svg" alt="form" /> ) 
        case "VerifyProduct": // 
            return ( <img src="../assets/svg/verify_products.svg" alt="verify product" /> ) 
        case "ManageProducts": // 
            return ( <img src="../assets/svg/manage_products.svg" alt="manage products" /> ) 
        default:
            return ( <FaUserAlt className={cssClass}/> )   
    }
}

export default React.memo( Icon )