
import React, { useEffect } from "react"
import "./UsersFormRow.css"
import "../UsersFormRows.css"
import useGetTimeLeft from "../../../hooks/dates/useGetTimeLeft"
import { useTranslation } from "react-i18next"
import { addUserFormGroupedReducer, openModalDeleteUserFormReducer, removeUserFormGroupedReducer, UserForm } from "../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice"
import { useAppDispatch } from "../../../../redux/store/store"
import useGetJobName from "../../../hooks/useGetJobName/useGetJobName"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons"

export interface UserFormRowProps {
    userForm: UserForm
    accessDeleteUser: boolean
    onPress: (id: string) => void
    checkboxValue: boolean
}



const UserFormRow = ({ userForm, checkboxValue, accessDeleteUser, onPress }: UserFormRowProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const prettyDate = useGetTimeLeft(userForm.timestamp)

    const jobName = useGetJobName(userForm?.skillId)


    useEffect(() => {

        const getData = () => {
            /*
            const user = UserInfoCache.userNameCache.get(userForm.requestBy)
            if(user?.name){
                setUserNameRequestBy(user.name)
            }else{
                getMinimalUserInfoData(userForm.requestBy)
                .then((userInfo: UserInfoCacheType) => {
                    setUserNameRequestBy(userInfo.name)
                })
            }

            if(userForm.userAssigned){
                const userPersonInvolved = UserInfoCache.userNameCache.get(userForm.userAssigned)
                if(userPersonInvolved?.name){
                    setUserSupportAssigned(userPersonInvolved.name)
                }else{
                    getMinimalUserInfoData(userForm.userAssigned)
                    .then((userInfo: UserInfoCacheType) => {
                        setUserSupportAssigned(userInfo.name)
                    }).catch((error: any) => {console.log("error", error)})
                }
            }
    
    
            getProfilePictureByUserId(userForm.requestBy, "small")
            .then((imgUrl) => {
                setUserImgRequestBy(imgUrl)
            })
                */
        }

        getData()
    }, [])

    const handleUserCheckbox = (event: any) => {

        // These two lines avoid to select the row when we click in open menu or delete job
        const styleClasses: string = event.target.className.baseVal ?? event.target.className ?? ""
        const styleClassesParent: string = event.target.parentNode.className.baseVal ?? event.target.parentNode.className ?? ""

        if(styleClasses.indexOf("no-select") > -1 || styleClassesParent.indexOf("no-select") > -1)
            return

        if(!checkboxValue){
            dispatch( addUserFormGroupedReducer( userForm ) )
        }else{
            dispatch( removeUserFormGroupedReducer(userForm.id) )
        }
    }

    const openDeleteUser = () => {
        dispatch( openModalDeleteUserFormReducer(userForm) )
    }

    
    return (
        <div onClick={handleUserCheckbox} id={`user-row-${userForm.id}`}  className={`con-row-user-form con-row width-100 flex fw-500 pointer ${checkboxValue ? "blue-row-background" : ""}`}>

            <div className="user-form-item1 center">
                <input 
                    type="checkbox" 
                    className="pointer" 
                    onChange={() => {}}
                    checked={checkboxValue}
                />
            </div>


            <div className="user-form-item2 height-100 flex flex-ai-center">
                <p className="fs-12 three-dot">{`${userForm.firstName} ${userForm.lastName}`}</p>
            </div>


            <div className="user-form-item3 height-100 flex flex-ai-center">
                <p className="fs-12">{userForm.phoneNumber}</p>
            </div>


            <div className="user-form-item4 height-100 flex flex-ai-center">
                <p className="fs-12 three-dot">{userForm.mail}</p>
            </div>

            {
                /*
                    There are some inconcistencies with id 00000 by all the changes, the code below is necessary
                */
            }
            <div className="user-form-item5 height-100 flex flex-ai-center">
                <p className="fs-12">{userForm?.skillId === "00000000-0000-0000-0000-000000000000" ? userForm.skillName : (jobName === "No name found" ? userForm.skillName : jobName) }</p>
            </div>


            <div className="user-form-item6 height-100 flex flex-ai-center">
                <p className={`fs-12 color-text`}>{userForm.state}</p>
            </div>

            <div className="user-form-item7 height-100 flex flex-ai-center">
                <p className={`fs-12 color-text`}>{userForm.city}</p>
            </div>


            <div className="user-form-item8 height-100 flex flex-ai-center">
                <p className={`fs-12 color-text`}>{userForm.timestamp.substring(0,10)}</p>
            </div>

            <div className="user-form-item9 height-100 center">
                <button onClick={openDeleteUser} className="p-3 pointer">
                    <FontAwesomeIcon icon={faTrashCan} className={"fs-16 color-text"} />
                </button>
            </div>

        </div>
    )
}

export default React.memo( UserFormRow )