import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { 
    getUserCredentials,
    adminApiUrl,
} from "../../axiosUtils";
import { JobCategoryJob } from "../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";


export const editMultipleJobs = (jobsGrouped: JobCategoryJob[]) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/job-category/jobs`, { jobsGrouped }, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/jobs`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteMultipleJobs = (categoryId: string, jobsIds: { id: string }[]) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                data: { jobsGrouped: jobsIds }
            };

            axios.delete(`${adminApiUrl}/v1/job-category/${categoryId}/jobs`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/jobs`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
