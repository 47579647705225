import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowTimeTransactionProps = {
    inputValues: ValuesForm
    isDay: boolean
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}


const RowTimeTransaction = ({ inputValues, isDay, onChange, onFocus }: RowTimeTransactionProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "timeTransactionGimIVA_Day",          placeholder: "Gim IVA",         width: "15.65%",   type: "text",   step: undefined,   readOnly: true },
        { name: "timeTransactionGimmerIVA_Day",       placeholder: "Gimmer IVA",      width: "15.65%",   type: "text",   step: undefined,   readOnly: true },
        { name: "timeTransactionGimworkIVA_Day",      placeholder: "Gimwork IVA",     width: "15.65%",   type: "text",   step: undefined,   readOnly: false },
        { name: "timeTransactionISR_Day",             placeholder: "ISR",             width: "15.65%",   type: "text",   step: undefined,   readOnly: false },
        { name: "timeTransactionRMP_Day",             placeholder: "RMP",             width: "15.65%",   type: "text",   step: undefined,   readOnly: false },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "timeTransactionGimIVA_Night",          placeholder: "Gim IVA",         width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "timeTransactionGimmerIVA_Night",       placeholder: "Gimmer IVA",      width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "timeTransactionGimworkIVA_Night",      placeholder: "Gimwork IVA",     width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "timeTransactionISR_Night",             placeholder: "ISR",             width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "timeTransactionRMP_Night",             placeholder: "RMP",             width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
    ], []);
    

    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#F3FAFE"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_04")}
                color="#F3FAFE"
            />
        </RowInputs>
    )
}

export default RowTimeTransaction