import React, { useEffect, useState } from 'react'
import { getProfilePictureByUserId, getUserDataById } from '../../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import "./CardUserBugReport.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type CardUserBugReportProps = {
    id: string
}

type UserCardData = {
    name: string
    gimScore: string
    gimmerScore: string
    email: string
}

const CardUserBugReport = ({ id }: CardUserBugReportProps) => {

    const [user, setUser] = useState<UserCardData | null>(null)
    const [imgUrl, setImgUrl] = useState("")

    useEffect(() => {
      
        const getData = () => {
            getUserDataById(id, { mail: 1, phoneNumber: 1, firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1 })
            .then((user: any) => {
                setUser({
                    name: `${user.firstName} ${user.lastName}`,
                    gimScore: user.gimScore?.toFixed(2) ?? "",
                    gimmerScore: user.gimmerScore?.toFixed(2) ?? "",
                    email: user.mail ?? "",
                })
            }).catch((error) => {  })

            getProfilePictureByUserId(id, "small")
            .then((url) => {
                setImgUrl(url)
            }).catch(() => {
    
            })
        }

        getData()

    }, [])
    

    return (
        <div className="card-user-bug-report center ml-8 border-radius-6">
            <div className="flex">

                <div className="flex">
                    <img 
                        src={imgUrl ?? "/user.svg"} 
                        className="img-card-user-bug-report circle" 
                        onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}    
                    />
                    <div className="flex flex-d-column flex-jc-sb ml-16" >
                        <p className="fs-14 fw-500 color-dark-gim">{user?.name}</p>
                        <p className="fs-14 color-text">{user?.email}</p>
                    </div>
                </div>      

            </div>   

        </div>
    )
}

export default React.memo( CardUserBugReport )