import { useState } from 'react'
import { useAppDispatch } from '../../../../redux/store/store';
import { RootState } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../LoadingSpinner';
import CancelButton from '../../../UIcomponents/Modals/CancelButton/CancelButton';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal';
import { clearJobsGroupedByCategory, JobGrouped } from '../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import "./DeleteMultipleJobsModal.css"
import { deleteMultipleJobs } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobsFunctions';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { deleteJobsGroupedByCategory, JobCategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import { useTranslation } from 'react-i18next';
import { deleteJobFromJobsNamesFile } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions';

export const DeleteMultipleJobsModal = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { categoryId, categoryName } = useSelector((state: RootState) => state.managerJobModals.DeleteMultipleJobsModal);
    const jobsGrouped = useSelector((state: RootState) => state.managerJobsGroupedData.jobsGrouped).filter((job: JobGrouped) => job.categoryId === categoryId);
    const [loading, setLoading] = useState<boolean>(false);
  

    const handleSubmit = (e :any) => {
        e.preventDefault();
  
        const jobsToSend = jobsGrouped.map((job: JobGrouped) => {
          return { id: job.id }
        })
        console.log("jobsToSend", jobsToSend)
        const jobsIdsToEliminate = jobsToSend.map(({id}) => id)
        setLoading(true)
        deleteMultipleJobs(categoryId, jobsToSend)
        .then(async(res) => {
            
            try {
                dispatch(clearJobsGroupedByCategory({ categoryId }))
                dispatch(deleteJobsGroupedByCategory({ categoryId, jobsGrouped: jobsToSend }))
                dispatch(resetValueFromState())
                await deleteJobFromJobsNamesFile(jobsIdsToEliminate)
            } catch (error) {
                
            }finally{
                setLoading(false)
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
        }).finally(() => {  })

    }

    const closeModal = () => {
        dispatch(resetValueFromState())
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>

            <TitleModal title={t("General:label_010")} />
            
            <DescriptionModal description={`
                ${t("General:label_109")} ${jobsGrouped.length} ${t("General:label_110")}
                 ${categoryName} ${t("General:label_111")}?
            `} />

            <p className="fw-600 fs-16 mt-20 mb-8">{t("General:label_112")}</p>
            {
                jobsGrouped.map((jobGrouped: JobGrouped, index: number) => {
                    return (
                        <p key={index} className="fw-500 fs-14 mt-8 mb-8"> - {jobGrouped.jobName}</p>
                    )
                })
            }
                
            <br />
            <br />
            <br />
            <div className="flex flex-jc-end">
                <CancelButton onPress={closeModal} />
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}
