
import React, { useState, useEffect } from "react"
import { useAppDispatch } from '../../../../redux/store/store'
import { getProfilePictureByUserId } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { UserInfoCache, UserInfoCacheType, getMinimalUserInfoData } from "../../../../utils/staticVariables/staticVariables"
import "./TransactionReportRow.css"
import { BugReportTableItem, updateSeenBugReportReducer } from "../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice"
import { updateBugReportSeenWeb } from "../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions"
import PriorityReportLabel from "../../../UIcomponents/Texts/PriorityReport/PriorityReport"
import useGetTimeLeft from "../../../hooks/dates/useGetTimeLeft"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { TransactionBugReportTableItem, TransactionTypeReport } from "../../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice"

export interface BugReportRowProps {
    transactionReport: TransactionBugReportTableItem
    onPress: (id: string, type: TransactionTypeReport) => void
}


const TransactionReportRow = ({ transactionReport, onPress }: BugReportRowProps) => {

    const { t } = useTranslation()

    const prettyDate = useGetTimeLeft(transactionReport.timestamp)

    const [userNameRequestBy, setUserNameRequestBy] = useState<string>("")
    const [userImgRequestBy, setUserImgRequestBy] = useState<string>("")
    const [userSupportAssigned, setUserSupportAssigned] = useState<string>("")


    useEffect(() => {

        const getData = () => {
            const user = UserInfoCache.userNameCache.get(transactionReport.requestBy)
            if(user?.name){
                setUserNameRequestBy(user.name)
            }else{
                getMinimalUserInfoData(transactionReport.requestBy)
                .then((userInfo: UserInfoCacheType) => {
                    setUserNameRequestBy(userInfo.name)
                })
            }
/*
            if(transactionReport.userAssigned){
                const userPersonInvolved = UserInfoCache.userNameCache.get(transactionReport.userAssigned)
                if(userPersonInvolved?.name){
                    setUserSupportAssigned(userPersonInvolved.name)
                }else{
                    getMinimalUserInfoData(transactionReport.userAssigned)
                    .then((userInfo: UserInfoCacheType) => {
                        setUserSupportAssigned(userInfo.name)
                    }).catch((error: any) => {console.log("error", error)})
                }
            }
    */
    
            getProfilePictureByUserId(transactionReport.requestBy, "small")
            .then((imgUrl) => {
                setUserImgRequestBy(imgUrl)
            })
        }

        getData()
    }, [])
    
    
    return (
        <div onClick={() => onPress(transactionReport.id, transactionReport.type)} className="container-row-report-bug flex mt-8 mb-8 pointer pl-10">
            
            {
                // Seen circle
                !transactionReport.seen &&
                <div className="con-circle-saw center">
                    <div className="circle-saw circle"></div>
                </div>
            }

            {
                // report Id
            }
            <div className="report-bug-item1 height-100 center">
                <p title={transactionReport.id} className="id-report-bug fs-14 fw-500">{transactionReport.id}</p>
            </div>

            {
                // request by
            }
            <div className="report-bug-item2 height-100 flex flex-ai-center">
                <div className="img-circular-row circle" style={{ backgroundImage: `url("${userImgRequestBy}")` }}></div>
                <p className="ml-10 fs-14 fw-500 three-dot">{userNameRequestBy}</p>
            </div>

            {
                // subject
            }
            <div className="report-bug-item3 height-100 flex flex-ai-center">
                <p className="fs-14 fw-500 three-dot">{transactionReport.subject }</p>
            </div>

            {
                // status
            }
            <div className="report-bug-item5 height-100 flex flex-ai-center">
                <PriorityReportLabel type={transactionReport.priority} />
            </div>

            {
                // Creation Date
            }
            <div className="report-bug-item6 height-100 flex flex-ai-center">
                <p className="fs-12 fw-600">{prettyDate ?? transactionReport.timestamp}</p>
            </div>

            {
                // Assignee
            }
            <div className="report-bug-item7 height-100 flex flex-ai-center">
                <p className={`fs-14 fw-600 color-text`}>{userSupportAssigned}</p>
            </div>


        </div>
    )
}

export default React.memo( TransactionReportRow )