import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import BackButton from '../../../UIcomponents/Buttons/BackButton/BackButton'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom'
import FilesInfo from './FilesInfo/FilesInfo'
import CardUserBugReport from './CardUserBugReport/CardUserBugReport'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'
import ButtonsTransactionReport from './ButtonsTransactionReport/ButtonsTransactionReport'
import CardTransactionData from './CardTransactionData/CardTransactionData'


const LeftSideBugReport = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()


    const report = useSelector((state: RootState) => state.transactionReports.transactionReport)


    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="flex flex-d-column" style={{ width: "53%" }}>

            <div className="flex flex-d-column" style={{ marginLeft: "7.6rem" }}>

                <p className="title-screen">{ report.subject }</p>
                <p className="fs-13 mt-8">{moment(report.timestamp).format("DD MMMM YYYY - HH:mm")}</p>

            </div>


            <div className="flex mt-13 mt-8">
                <div className="" >
                    <BackButton goBack={goBack} />
                </div>
                <div className="flex flex-1 flex-ai-center pl-6">
                    <div className="gray-h-line"></div>
                </div>
            </div>
            
            <div className="flex flex-d-column" style={{ marginLeft: "7.6rem"}}>
                {
                    // button
                }

                <div className="center mb-14">
                    <CardUserBugReport id={report.requestBy} />
                </div>
                
                <CardTransactionData />

                {
                    // attachments
                }
                <p className="fs-13 fw-700 mt-26 mb-6">{t("General:label_196")}</p>
                <FilesInfo id={ report.id } type={report.type} />

                {
                    //description
                }
                <div className="mt-16">

                    <p className="fs-13 fw-700 mt-20 mb-8">{t("General:label_173") + ":"}</p>
                    <textarea rows={3} readOnly className="width-100 fs-13 p-8 border-radius-3 resize-none outline-none" style={{ borderColor: "black", lineHeight: "2rem", height: "18rem"}} value={ report.description }/> 

                </div>

                {
                    <div className="flex flex-jc-sb mt-40">

                        <ButtonsTransactionReport />

                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo( LeftSideBugReport )