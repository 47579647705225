import React, { ChangeEvent, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { resetManageUsersModalsReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice"
import { RootState, useAppDispatch } from "../../../../redux/store/store"
import { notifCustomUser, notifEmailUser, NotifEmailUserBody } from "../../../../utils/requestsUtils/functionsRequests/notify/notifyFunctions"
import { MyYup } from "../../../../utils/verifications/myYup"
import LoadingSpinner from "../../../LoadingSpinner"
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./SendNotificationModal.css"
import InputList from "../../../UIcomponents/InputList/InputList"
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from "../../../../utils/verifications/Forms"
import GeneralInputTitle from "../../../UIcomponents/GeneralInputTitle/GeneralInputTitle"

export type MessageType = "MESSAGE" | "NOTIFICATION"
type MessageTypeOption = { text: string, type: MessageType }

export type ForWhom = string
type ForWhomOption = { text: string, type: ForWhom }

const SendNotificationModal = () => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const [messageType, setMessageType] = useState<MessageTypeOption | null>()
    const [forWhom, setForWhom] = useState<ForWhomOption | null>()

    const [forWhomError, setForWhomError] = useState("")

    const roles = useSelector((state: RootState) => state.manageRoles.roles)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        title: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        body: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
    })

    const optionsTypeTopic: MessageTypeOption[] =  [
        { text: t("General:label_279"), type: "NOTIFICATION" }
    ]

    const forWhomTopicOptions: ForWhomOption[] =  [
        { text: t("General:label_280"), type: "ALL" },
        
    ].concat( roles.map(role => ({ text: role.id.replace(/\_/g, " "), type: role.id }) ) )

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const handleSubmit = (e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput, inputValues))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        setLoading(true)

        const title = inputValues["title"].value
        const body = inputValues["body"].value
        const topic = forWhom?.type

        if(!topic){
            setForWhomError(t("General:label_276") ?? "")
            return 
        }else if(forWhomError){
            setForWhomError("")
        }

        notifCustomUser({ title, body, topic })
        .then(() => {
            dispatch(resetManageUsersModalsReducer())
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }

    const selectType = (type: MessageTypeOption) => {
        setMessageType(type)
    }


    const selectTypeForWhom = (type: ForWhomOption) => {
        setForWhom(type)
    }

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }



    return (
        <form onSubmit={handleSubmit} className="blank-wrapper-modals">
            { loading && <LoadingSpinner fullPage={true} /> }
            <TitleModal title={`${t("General:label_144")}`} />

            <p className="fw-600 fs-14 mt-30 mb-10">
                {t("General:label_282")}
            </p>

            <InputList 
                text={messageType?.text ?? ""}  
                width="100%"
                height={"3vh"}
                fontSize="fs-14-vh"
            >
                {
                    optionsTypeTopic
                    .map((option: MessageTypeOption) => {
                        return(
                            <button 
                                key={option.type} 
                                className="darks-white-bg pointer flex flex-ai-center pl-14 pr-14 width-100 pt-4 pb-4" 
                                onClick={() => selectType(option)} 
                                type="button" 
                            >
                                <p className="fs-14-vh pl-12 text-align-left">{option.text}</p>
                            </button>
                        )
                    })
                }
            </InputList>

            <div className="mt-20">

                <p className="fw-600 fs-14 mt-30 mb-10">
                    {t("General:label_281")}
                </p>

                <InputList 
                    text={forWhom?.text ?? ""}  
                    width="100%"
                    height={"3vh"}
                    fontSize="fs-14-vh"
                >
                    {
                        forWhomTopicOptions
                        .map((option: ForWhomOption) => {
                            return(
                                <button 
                                    key={option.type} 
                                    className="darks-white-bg pointer flex flex-ai-center pl-14 pr-14 width-100 pt-4 pb-4" 
                                    onClick={() => selectTypeForWhom(option)} 
                                    type="button" 
                                >
                                    <p className="fs-14-vh pl-12 text-align-left">{option.text}</p>
                                </button>
                            )
                        })
                    }
                </InputList>
            </div>

            <div className="mt-30">
                <GeneralInputTitle 
                    title={t("General:label_277")}
                    name={"title"}
                    value={inputValues["title"].value}
                    onChange={onChangeValueInput}
                    onFocus={onChangeFocusInput}
                    placeholder={t("General:label_277")}
                    error={inputValues["title"].error}
                />
            </div>

            <GeneralInputTitle 
                title={t("General:label_278")}
                name={"body"}
                value={inputValues["body"].value}
                onChange={onChangeValueInput}
                onFocus={onChangeFocusInput}
                placeholder={t("General:label_278")}
                error={inputValues["body"].error}
                textArea={{ height: "10rem" }}
            />



            <div className="p-relative mt-2" style={{ height: "3rem" }}>
                {
                    <p className="p-absolute color-error fs-12">{errorMessage}</p>
                }
            </div>
            
            <ConfirmButton text={t("General:label_016")} width="100%" />
        </form>
    )
}

export default React.memo( SendNotificationModal )