import React from 'react'
import "./CardTransactionDocumentsData.css"

const CardTransactionDocumentsData = () => {


    return (
        <div className="card-transaction-documents-report center ml-8 border-radius-6">
            <div className="center flex-d-column">
                <img src="../assets/svg/tax_data.svg" alt="tax data" />
                <p className="fs-12 color-text mt-10">Tax data</p>
            </div>
        </div>
    )
}

export default React.memo( CardTransactionDocumentsData )