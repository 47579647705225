import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowNightHoursProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}

const RowNightHours = ({ inputValues, valueCheckBox, isDay, onChangeCheckbox, onChange, onFocus }: RowNightHoursProps) => {

    const { t } = useTranslation()

    const data: DataInputRowJob[] = useMemo(() => [
        { name: "nightStartTime",           placeholder: t("ManageJobs:label_06"),      width: "22%",   type: "text",   step: 10,   readOnly: false },
        { name: "nightEndTime",             placeholder: t("ManageJobs:label_07"),      width: "22%",   type: "text",   step: 1,    readOnly: false },
    ], []);

    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={data}
            onChange={onChange}
            onFocus={onFocus}
            color="#F8E2D7"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_02")}
                color="#F8E2D7"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowNightHours