import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { PriorityReport, StatusTransactionReport, TransactionReport } from '../../../../utils/dbModels'
import { getBillingReportById, getBillingReports, getPaymentReportById, getPaymentReports } from '../../../../utils/requestsUtils/functionsRequests/reports/transactions/transactionFunctions'

export type TransactionTypeReport = "Billing" | "Payment"

export type TotalCountersStatus = {
    opened: number
    pending: number
    closed: number
}       

export type LastEvaluatedKeyTransactionBugReports = {
    id: string
    timestamp?: string
    status?: StatusTransactionReport
}

export type ActionTransactionBugReport = "TAKE" | "SEND_VERIFY" | "VALIDATION" | "VERIFY"

type GetTransactionBugReportsResponse = { 
    results: TransactionBugReportTableItem[], 
    LastEvaluatedKey?: LastEvaluatedKeyTransactionBugReports, 
    page: number, 
    limit: number, 
    bringTotal: boolean, 
    total: number, 
    status: StatusTransactionReport 
    totalCounterStatus?: TotalCountersStatus
    type: TransactionTypeReport
}

export type ExtraDataTransactionBugReport = {
    LastEvaluatedKey?: LastEvaluatedKeyTransactionBugReports,
    page: number,
    limit: number,
    bringTotal: boolean
    status: StatusTransactionReport
    type: TransactionTypeReport
}

export type TransactionBugReportTableItem = TransactionReport & {
    timestampMilliseconds: number
    //creationDate?: string
    seen: boolean
    type: TransactionTypeReport
}
  
const addRemainingFieldstransactionReport = (transactionReport: TransactionReport, type: TransactionTypeReport): TransactionBugReportTableItem => {
    return {
        ...transactionReport,
        seen: transactionReport.seen ?? false,
        timestampMilliseconds: new Date(transactionReport.timestamp).getTime(),
        type
    }
}

/*
const addExtraDetailsMessages = (messages?: MessageTransactionBugReport[]) => {
    if(!messages)
        return
    let previousUserId: string | null = null
    for(let i=0; i<messages.length; i++){
        if(messages[i].userId !== previousUserId){
            messages[i].isFirstGroupMessage = true 
        }
        previousUserId = messages[i].userId
    }
}
*/

export const GetTransactionBugReportsThunk = createAsyncThunk(
    'transactionBugReportsSlice/GetTransactionBugReportsThunk',
    async ({ LastEvaluatedKey, page, limit, bringTotal, status, type }: ExtraDataTransactionBugReport) => {
      try { 

        let LastEvaluatedKeyStr = JSON.stringify(LastEvaluatedKey)
        let res: any = { results: [] }
        if(type === "Billing"){
            res = await getBillingReports({ LastEvaluatedKey: LastEvaluatedKeyStr, limit, bringTotal, status })
            res.results = res.results.map((transactionReport: TransactionBugReportTableItem) => {
                return addRemainingFieldstransactionReport(transactionReport, "Billing")
            })
        }else{
            res = await getPaymentReports({ LastEvaluatedKey: LastEvaluatedKeyStr, limit, bringTotal, status })
            res.results = res.results.map((transactionReport: TransactionBugReportTableItem) => {
                return addRemainingFieldstransactionReport(transactionReport, "Payment")
            })
        }


        return {
          results: res.results as TransactionBugReportTableItem[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          page,
          limit,
          bringTotal,
          total: res.total,
          status,
          totalCounterStatus:  res.totalCounterStatus,
          type
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as TransactionBugReportTableItem[],
          page,
          limit,
          bringTotal,
          total: 0,
          status: "OPENED" as StatusTransactionReport,
          type
        } 
      }
    }
)



export const GetTransactionBugReportByIdThunk = createAsyncThunk(
    'transactionBugReportsSlice/GetTransactionBugReportByIdThunk',
    async ({ id, type }: { id: string, type: TransactionTypeReport }) => {

        if(type === "Billing"){
            return getBillingReportById(id)
            .then((item) => {
                return {
                    transactionReport: addRemainingFieldstransactionReport(item, "Billing")
                }
            }).catch(() => {
                throw("ERROR_GETTING_APP_REPORT")
            })
        }else{
            return getPaymentReportById(id)
            .then((item) => {
                return {
                    transactionReport: addRemainingFieldstransactionReport(item, "Payment")
                }
            }).catch(() => {
                throw("ERROR_GETTING_APP_REPORT")
            })
        }


    }
)

export const numberTransactionBugReportsByPage: number[] = [ 10, 25, 50, 100 ]

type initialStateType = {
  stringToSearch: string
  total: number
  indexTab: number
  limit: number
  page: number
  type: TransactionTypeReport
  transactionReportsIds: { id: string, timestamp?: string }[]
  transactionReportsToShow: TransactionBugReportTableItem[],
  LastEvaluatedKey?: LastEvaluatedKeyTransactionBugReports,
  loadingContainer: boolean,
  orderColumn: string,
  orderDirection: boolean,
  transactionReport: TransactionBugReportTableItem,
  loadingIndividualPage: boolean
  status: StatusTransactionReport
  totalCounterStatus?: TotalCountersStatus
}

const initialState: initialStateType = {
    stringToSearch: "",
    total: 0,
    indexTab: 0,
    limit: numberTransactionBugReportsByPage[0],
    page: 1,
    type: "Billing",
    transactionReportsIds: [],
    transactionReportsToShow: [] as TransactionBugReportTableItem[],
    LastEvaluatedKey: { id: "" },
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    transactionReport: {} as TransactionBugReportTableItem,
    loadingIndividualPage: true,
    status: "OPENED"
}

export const transactionBugReportsSlice = createSlice({
  name: 'manageTransactionBugReports',
  initialState,
  reducers: {

    updateSeenTransactionBugReportReducer: (state, action: PayloadAction<{ id: string }>) => {
        state.transactionReportsToShow = state.transactionReportsToShow.map((transactionReport: TransactionBugReportTableItem) => {
          if(transactionReport.id === action.payload.id)
              transactionReport.seen = true
              //transactionReport.status = "OPENED"
          return transactionReport
        })
        return state
    },
    /*
    removeDeletedTransactionBugReportReducer: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      if(id === state.LastEvaluatedKey?.id && state.transactionReportsIds.length > 1){
        //const position = state.transactionReportsIds.indexOf(id)
        const transactionReport = state.transactionReportsToShow.find((transactionReport: TransactionBugReportTableItem) => transactionReport.id === action.payload.id)
        if(state.LastEvaluatedKey){
          state.LastEvaluatedKey?.id = transactionReport?.id 
        }
          
      }
      state.transactionReportsIds = state.transactionReportsIds.filter((transactionReport: string) => transactionReport !== action.payload.id)
      state.transactionReportsToShow = state.transactionReportsToShow.filter((transactionReport: TransactionBugReportTableItem) => transactionReport.id !== action.payload.id)
      state.total = state.total - 1
      return state
    },
    */
    changeIndexTabReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
      state.transactionReportsIds = []
      state.indexTab = action.payload.indexTab
      return state
    },
    orderTransactionBugReportsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
      const { orderColumn, orderDirection  } = action.payload
        /*
      state.transactionReportsToShow = state.transactionReportsToShow.map((transactionReport: TransactionBugReportTableItem) => {

        //const bug = UserInfoCache.bugNameCache.get(transactionReport.bugId)
        //if(bug?.name)
        //  transactionReport.bugName = bug.name

        return JSON.parse( JSON.stringify(transactionReport) )

      })*/
      state.transactionReportsToShow = getOrderedState({ orderColumn, orderDirection, array: state.transactionReportsToShow })
      state.orderColumn = orderColumn
      state.orderDirection = orderDirection
              
      return state
    },
    updateStatusTransactionBugReportReducer: (state, action: PayloadAction<{ id: string, status: StatusTransactionReport }>) => {
      state.transactionReportsToShow = state.transactionReportsToShow.map((transactionReport: TransactionBugReportTableItem) => {
        if(transactionReport.id === action.payload.id)
            transactionReport.status = action.payload.status

        return transactionReport
      })
      return state
    },
      changeIndexTransactionBugTabRowsReducer: (state, action: PayloadAction<number>) => {
          state.indexTab = action.payload
          return state
      },
    /*
    addMessageTransactionBugReportReducer: (state, action: PayloadAction<MessageTransactionBugReport>) => {
        if(state.transactionReport.messages){
          state.transactionReport.messages?.push(action.payload)
        }else{
          state.transactionReport.messages = [ action.payload ]
        }
        addExtraDetailsMessages(state.transactionReport.messages)
        return state
    },
    updateUserAssignedTransactionBugReportReducer: (state, action: PayloadAction<string>) => {
      state.transactionReport.userAssigned = action.payload
      return state
    },
    updateTesterAssignedTransactionBugReportReducer: (state, action: PayloadAction<string>) => {
      state.transactionReport.testerAssigned = action.payload
      return state
    },
    */
    updatePriorityTransactionBugReportReducer: (state, action: PayloadAction<{ id: string, priority: PriorityReport }>) => {
      state.transactionReportsToShow = state.transactionReportsToShow.map((transactionReport: TransactionBugReportTableItem) => {
        if(transactionReport.id === action.payload.id)
            transactionReport.priority = action.payload.priority
  
        return transactionReport
      })
      return state
    },
    /*
    addHistoryItemTransactionBugReport: (state, action: PayloadAction<HistoryTransactionBugReport>) => {
      if(state.transactionReport.history){
          state.transactionReport.history.push( action.payload )
      }else{
          state.transactionReport.history = [ action.payload ]
      }
      return state
    },
    */
    /*
    setDetailedTransactionBugReportReducer: (state, action: PayloadAction<{ transactionReport: TransactionBugReportTableItem }>) => {
      state.transactionReport = action.payload.transactionReport 
      return state
    },
    */
    setLoadingTransactionBugReportReducer: (state, action: PayloadAction<boolean>) => {
      state.loadingIndividualPage = action.payload
      return state
    },
    setNewStatusTransactionBugReportReducer: (state, action: PayloadAction<{newStatus: StatusTransactionReport}>) => {
      state.transactionReport.status = action.payload.newStatus  
      return state
    },
    setNewPriorityTransactionBugReportReducer: (state, action: PayloadAction<{newPriority: PriorityReport}>) => {
      state.transactionReport.priority = action.payload.newPriority  
      return state
    },
    resetDetailedTransactionBugReportReducer: (state) => {
      state.transactionReport = {} as TransactionBugReportTableItem
      state.loadingIndividualPage = true
      return state
    },
    resetManageTransactionBugReportsReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetTransactionBugReportsThunk.fulfilled, (state, action: PayloadAction<GetTransactionBugReportsResponse>) => {

      const { results, page, LastEvaluatedKey, limit, bringTotal, total, status, totalCounterStatus, type } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          state.transactionReportsIds[skip+i] = { id: results[i].id, timestamp: results[i].timestamp }
          lastIndex = skip+i
      }
      
        state.transactionReportsIds = state.transactionReportsIds.slice(0,lastIndex+1)

        const itemsOrderedByDate = getOrderedState({ 
            array: results, 
            orderColumn: state.orderColumn ? state.orderColumn : "timestamp", 
            orderDirection: state.orderColumn ? state.orderDirection : false
        })
        state.transactionReportsToShow = itemsOrderedByDate
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? { id: "" }
        state.limit = limit ?? 0
        console.log("STATUS", status)
        state.status = status
        state.type = type
        state.indexTab = type === "Billing" ? 0 : 1
        
        if(bringTotal)
            state.total = total

        if(totalCounterStatus)
            state.totalCounterStatus = totalCounterStatus

        state.stringToSearch = ""
        return state
                
      }).addCase(GetTransactionBugReportsThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetTransactionBugReportsThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      


      .addCase(GetTransactionBugReportByIdThunk.fulfilled, (state, action: PayloadAction<{ transactionReport: TransactionBugReportTableItem }>) => {
        const { transactionReport } = action.payload
        if(transactionReport.id){
          //addRemainingFieldstransactionReport(transactionReport)
          state.transactionReport = transactionReport
        }
        state.loadingIndividualPage = false
        return state

      })
      .addCase(GetTransactionBugReportByIdThunk.pending, (state, action: any) => {
        state.loadingIndividualPage = true
          return state     
      }).addCase(GetTransactionBugReportByIdThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingIndividualPage = false
          return failState
      })
      
  },

});

// Action creators are generated for each case reducer function
export const {  
  updateSeenTransactionBugReportReducer, 
  changeIndexTabReducer,
  resetManageTransactionBugReportsReducer,
  orderTransactionBugReportsReducer,
  updateStatusTransactionBugReportReducer,
  updatePriorityTransactionBugReportReducer,
  setNewStatusTransactionBugReportReducer,
  setNewPriorityTransactionBugReportReducer,
  resetDetailedTransactionBugReportReducer,
  setLoadingTransactionBugReportReducer,
  changeIndexTransactionBugTabRowsReducer
} = transactionBugReportsSlice.actions

export default transactionBugReportsSlice.reducer