import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { ProductDB } from '../../../redux/slices/components/Products/ProductsSlice';
import ProductsTableRow from './ProductsTableRow/ProductsTableRow';
import LoadingSpinner from '../../LoadingSpinner';
import { showDeleteProductDBModalReducer, showEditProductDBModalReducer } from '../../../redux/slices/components/ManageProducts/manageProductsDataSlice';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';

type ProductsTableRowsProps = {

}

const ProductsTableRows = ({  }: ProductsTableRowsProps) => {

    const dispatch = useAppDispatch()

    const products = useSelector((state: RootState) => state.manageProductsData.productsToShow)
    const loadingContainer = useSelector((state: RootState) => state.manageProductsData.loadingContainer)

    const accessEdit = useCheckAccess({ id_Category: "Manage_Products", id_Policy: "Products", id_Action: "Edit" })
    const accessDelete = useCheckAccess({ id_Category: "Manage_Products", id_Policy: "Products", id_Action: "Delete" })

    const openDeleteModal = useCallback(
        (product: ProductDB) => {
            dispatch( showDeleteProductDBModalReducer(product) )
        },
        [],
    )

    const openEditModal = useCallback(
        (product: ProductDB) => {
            dispatch( showEditProductDBModalReducer(product) )
        },
        [],
    )
        

    return (
        <div className="flex flex-d-column flex-1 p-relative height-100">
            { loadingContainer && <LoadingSpinner fullPage={false} /> }
            {
                products.map((product) => {
                    return(
                        <ProductsTableRow 
                            key={product.id}
                            product={product}
                            openDeleteModal={openDeleteModal}
                            openEditModal={openEditModal}
                            edit={accessEdit}
                            deleted={accessDelete}
                        />
                    )
                })
            }
        </div>
    )
}

export default React.memo( ProductsTableRows )