import React, { useEffect, useState } from 'react'
import { GetTransactionBugReportByIdThunk, resetDetailedTransactionBugReportReducer, TransactionTypeReport } from '../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'
import LeftSideTransactionReport from './LeftSideTransactionReport/LeftSideTransactionReport'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import LoadingSpinner from '../../LoadingSpinner'
import RightSideTransactionReport from './RightSideTransactionReport/RightSideTransactionReport'

const DetailedTransactionReportScreen = () => {

    const dispatch = useAppDispatch()

    const [errorType, setErrorType] = useState("")

    const loadingIndividualPage = useSelector((state: RootState) => state.transactionReports.loadingIndividualPage)
    const idReportBrought = useSelector((state: RootState) => state.transactionReports.transactionReport.id)

    const { id } = useParams();

    const [searchParams] = useSearchParams();

    const accessDisplay = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Transaction_Reports", id_Action: "Display" })

    const type = searchParams.get("type") as TransactionTypeReport

    useEffect(() => {

        if(id && type){
            if(["Billing", "Payment"].includes(type))
                dispatch( GetTransactionBugReportByIdThunk({ id, type }) )
            else{
                setErrorType("Type not valid")
            }
        }

        return () => {
            dispatch( resetDetailedTransactionBugReportReducer() )
        }

    }, [id, type])

    if(errorType)
        return (
            <div className="p-absoulte width-100 height-100 center">
                <p className="fs-20 fw-600 color-text">{errorType}</p>
            </div>
        )

    if (loadingIndividualPage) 
        return (
            <div className="width-100 height-100 p-relative">
                <LoadingSpinner fullPage={false} /> 
            </div>
        )
    
    if(!accessDisplay){
        return (
            <div className="p-absoulte width-100 height-100 center">
                <p className="fs-20 fw-600 color-text">{"You can access to this content"}</p>
            </div>
        )
    }
    
    return (
        <div className="flex width-100 ff-poppins z-index-1" style={{ zIndex: 1, backgroundColor: "white" }}>
            <LeftSideTransactionReport />
            <RightSideTransactionReport />
        </div>
    )
}

export default DetailedTransactionReportScreen 