import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import "./HeaderTableTransactionReport.css"
import { orderTransactionBugReportsReducer } from '../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice';


const HeaderTableTransactionReport = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.transactionReports.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.transactionReports.orderColumn)

    //const orderColumn = "a"
    //const orderDirection = false

    const orderArray = //useCallback(
        ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => { 
            dispatch(orderTransactionBugReportsReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
        }//,[orderColumn, orderDirection],)

    return (
        <div className="flex con-header-table-report-bug ff-roboto mt-24">

            <div className="report-bug-item1 con-item-header-report-bug pl-12">
                <ArrowColumn 
                    columnText={t("General:label_154")}
                    orderColumn={orderColumn} 
                    columnName={"id"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-bug-item2 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_155")}
                    orderColumn={orderColumn} 
                    columnName={"requestBy"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="report-bug-item3 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_162")}
                    orderColumn={orderColumn} 
                    columnName={"subject"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="report-bug-item5 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_163")}
                    orderColumn={orderColumn} 
                    columnName={"priority"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-bug-item6 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_158")}
                    orderColumn={orderColumn} 
                    columnName={"creationDate"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-bug-item7 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_159")}
                    orderColumn={orderColumn} 
                    columnName={"userAssigned"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
        
        </div>
    )
}

export default HeaderTableTransactionReport