import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { changeIndexTransactionBugTabRowsReducer, TransactionTypeReport } from '../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'


type TabOfferOption = { index: number }

const colorsAnimation = ["blue", "red"]


const TabBarTransactionReports = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const [leftLine, setLeftLine] = useState<number>(0)

    const indexTabRows = useSelector((state: RootState) => state.transactionReports.indexTab);
    const limit = useSelector((state: RootState) => state.transactionReports.limit);
    const status = useSelector((state: RootState) => state.transactionReports.status);
    const type = useSelector((state: RootState) => state.transactionReports.type);

    useEffect(() => {
        const type = searchParams.get("type") as TransactionTypeReport
        
        if(type === "Billing")
            setTab({ index: 0 }, true)
        else
            setTab({ index: 1 }, true)

    }, [])
    

/*
    useEffect(() => {
            // Get a specific parameter value
        const limitParam = searchParams.get("General:limit") ?? "10"
        const pageParam = searchParams.get("General:page") ?? "1"
        const typeParam = searchParams.get("General:type") ?? "10"

        let type: TypeOffers = "Task"
        if(typeParam === "Job")
            type = "Job"

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        
        let page = 1
        if( canBeInteger(pageParam) )
            page = Number(pageParam)

        let index = 1
        if(type === "Job")
            index = 2

        setTab({ index })
        dispatch(changeIndexTabReducer({ indexTab: index }))
        dispatch(GetOffersThunk({ type, page, limit, bringTotal: true }))

    }, [searchParams.get("General:page"), searchParams.get("General:limit"), searchParams.get("General:type")])
*/

    useEffect(() => {
        if(indexTabRows < 0)
            setLeftLine(0)
    }, [indexTabRows])
    

    const setTab = ({ index }: TabOfferOption, notExecuteFirstRender: boolean = false) => {
        //console.log("index === indexTabRows", index === indexTabRows, index, indexTabRows)
        if(index === indexTabRows)
            return

        const smallLine = document.getElementById("small-line") 

        if(smallLine){

            const marginBetweenOptions = 1; // rem
            const widthOption = 10; // rem

            const remsToMove = ((index - indexTabRows) * (widthOption+marginBetweenOptions) );

            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });

            setLeftLine(remsToMove + leftLine)
            dispatch( changeIndexTransactionBugTabRowsReducer( index ) )
            /*
            dispatch(GetTransactionBugReportsThunk({ 
                page: 1, 
                limit, 
                bringTotal: true, 
                type, 
                status
            }))
                */
            if(notExecuteFirstRender)
                return

            const type = index === 1 ? "Payment" : "Billing"
            if(status)
                navigate(`/transactions-bugs?limit=${limit}&page=${1}&type=${type}&status=${status}`)
            else
                navigate(`/transactions-bugs?limit=${limit}&page=${1}&type=${type}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }
    

    return (
        <div id="container-tab" className="con-all-options-tab">

            <div 
                className={`option-tab ${indexTabRows === 0 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 0 })}
            >
                <p className={`text-option-tab ${indexTabRows === 0  ? "italic text-tab-blue" : ""}`}>{"Billing"}</p>
                <div id="1-option" className="option-line"></div>
            </div>
            
            <div 
                className={`option-tab ${indexTabRows === 1 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 1  })}
            >
                <p className={`text-option-tab ${indexTabRows === 1  ? "italic text-tab-red" : ""}`}>{"Payment"}</p>
                <div id="2-option" className="option-line"></div>
            </div>

                <div 
                    id="small-line" 
                    style={{ left: `${leftLine.toString()}rem` }} 
                    className={`small-line ${colorsAnimation[indexTabRows]}-line ${indexTabRows < 0 ? "visibility-hidden" : ""}`}
                ></div>
            
            <div className="whole-line"></div>

        </div>
    )
}

// 33cef213-1c57-47aa-814e-16f1bba7bb70
// ${indexTab < 0 ? "visibility-hidden" : ""}
export default React.memo( TabBarTransactionReports )