import React from 'react'
import "./CardBillingTransactionReport.css"

const CardBillingTransactionReport = () => {


    return (
        <div className="card-transaction-documents-report flex flex-jc-sb flex-ai-center ml-8 border-radius-6">
            <p className="fs-14 fw-600">Billing reqest date</p>
            <p className="fs-14">Date request</p>
        </div>
    )
}

export default React.memo( CardBillingTransactionReport )