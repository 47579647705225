import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./ProductsTableRow.css"
import { ProductDB } from '../../../../redux/slices/components/Products/ProductsSlice'

type ProductsTableRowProps = {
    product: ProductDB
    //onPress: () => void
    openDeleteModal: (product: ProductDB) => void
    openEditModal: (product: ProductDB) => void

    edit: boolean
    deleted: boolean
}

const ProductsTableRow = ({
    product,

    openDeleteModal,
    openEditModal,

    edit,
    deleted
}: ProductsTableRowProps) => {


    return (
        <div className="products-row-card flex mt-8">
            <div className="products-item1 flex flex-ai-center ">
                <p className="fs-14-vh fw-600">{product.productName}</p>
            </div>

            <div className="products-item2 flex flex-ai-center">
                <p className="fs-14-vh fw-600 text-3-points">{product.description}</p>
            </div>
            
            <div className="products-item3 flex flex-ai-center center">
                <p className="fs-14-vh fw-600">{`${product.IVA_GIMWORK}%`}</p>
            </div>
            
            <div className="products-item3 flex flex-ai-center center">
                <p className="fs-14-vh fw-600">{`${product.IVA_GIMMER}%`}</p>
            </div>
            
            <div className="products-item3 flex flex-ai-center center">
                <p className="fs-14-vh fw-600">{`${product.RMPC}%`}</p>
            </div>
            
            <div className="products-item6 center">
                {  
                    product.typeProduct === "DIGITAL"  &&
                    <button 
                        //onClick={() => downloadFile(id, File.name, type, File.type)}  
                    >
                        <p className="fs-14-vh fw-600">{product.fileType.join(",").replace(/\,/g, " / ")}</p>
                    </button> }
            </div>
            
            <div className="products-item7 center">
                {
                    edit &&
                    <button className="pointer" onClick={() => openEditModal(product)} > <FontAwesomeIcon icon={solid("pen")} className="fs-20-vh" /> </button>
                }
            </div>
            
            <div className="products-item8 center">
                {
                    deleted &&
                    <button className="pointer" onClick={() => openDeleteModal(product)} > <FontAwesomeIcon icon={solid("trash")} className="fs-20-vh" /> </button>
                }
            </div>

        </div>
    )
}

export default React.memo( ProductsTableRow )