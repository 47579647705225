import React, { useCallback, useEffect, useState } from 'react'
import { FileInfo } from '../../../../../utils/requestsUtils/functionsRequests/reports/files/filesFunctions'
import FileButton from '../../../../UIcomponents/Buttons/FileButton/FileButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./FilesInfo.css"
import { TransactionTypeReport } from '../../../../../redux/slices/components/TransactionsBugs/TransactionsBugsSlice'
import { getFilesTransactionReport } from '../../../../../utils/requestsUtils/functionsRequests/reports/transactions/transactionFunctions'


type FilesInfoProps = {
    id: string
    type: TransactionTypeReport
}

const FilesInfo = ({ id, type }: FilesInfoProps) => {

    const [filesInfo, setFilesInfo] = useState<FileInfo[]>([])
    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [indexSelected, setIndexSelected] = useState(-1)


    useEffect(() => {
        if(id){
            getFilesTransactionReport(id, type)
            .then((files: FileInfo[]) => {
                setFilesInfo(files)
            })
            .catch(() => {
    
            })
        }

    }, [])

    const onPress = useCallback(
        (index: number) => {
            setIndexSelected(index)
            setShowPreview(true)
        },
      [],
    )

    const changeFile = (newIndex: number) => {

        if(newIndex < 0)
            return
        else if(newIndex >= filesInfo.length)
            return
        
        setIndexSelected(newIndex)
    }

    const handleClickOutsideModal = (e: any) => {
        const clickInsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickInsideModal)
            setShowPreview(false)
    }
    
    return (
        <div className="flex width-100 wrap">
            {
                filesInfo.map((file: FileInfo, index: number) => {
                    return(
                        <FileButton 
                            key={index}
                            onPress={onPress}
                            type={file.type}
                            urlFile={file.url}
                            numberFile={file.numberFile}
                            index={index}
                        />
                    )
                })
            }
            
            {
                showPreview && 
                <div onClick={(e) => { handleClickOutsideModal(e) }} id={"outside-background"} className="fixed-all-screen background-dark center">

                    <div id={"background-aux-modal"} className="height-100 flex flex-d-column flex-ai-center con-file-content">
                        
                        <div className="flex flex-1 flex-ai-center" style={{ overflowY: "hidden" }}>

                            <div 
                                onClick={() => changeFile(indexSelected - 1)} 
                                className={`center circle mt-20 pointer mr-10 size-buttons ${indexSelected > 0 ? "con-icon-close-cer-img" : ""}`}
                            >
                                <FontAwesomeIcon icon={regular("chevron-left")} className="fs-18 c-white" />
                            </div>

                            {
                                filesInfo[indexSelected]?.type === "video"
                                ?

                                    <video className='con-file-info' controls>
                                        <source src={filesInfo[indexSelected]?.url} type={`video/${filesInfo[indexSelected]?.suffix}`} />
                                    </video>
                                :
                                /*
                                    <div 
                                        className="con-file-info"
                                        style={{ backgroundImage: indexSelected >= 0 ? `url("${filesInfo[indexSelected]?.url}")` : "" }}
                                    >

                                    </div>
                                    */
                                   <img className="con-file-info" src={indexSelected >= 0 ? filesInfo[indexSelected]?.url : "" } alt="file" />
                            }

                            <div 
                                onClick={() => changeFile(indexSelected + 1)} 
                                className={`center circle mt-20 pointer ml-10 size-buttons ${indexSelected < filesInfo.length - 1 ? "con-icon-close-cer-img" : ""}`}
                            >
                                <FontAwesomeIcon icon={regular("chevron-right")} className="fs-18 c-white" />
                            </div>

                        </div>

                        <div onClick={() => setShowPreview(false)} className="center circle mt-16 pointer con-icon-close-cer-img"> 
                            <FontAwesomeIcon icon={regular("times")} className="fs-18 c-white" />
                        </div>

                    </div>

                </div>
            }
        </div>
    )
}

export default React.memo( FilesInfo )