import React from 'react'
import CardTransactionDocumentsData from './CardTransactionDocumentsData/CardTransactionDocumentsData'
import CardBillingTransactionReport from './CardBillingTransactionReport/CardBillingTransactionReport'

const RightSideTransactionReport = () => {
    return (
        <div className="pl-20 pb-10 mb-10 mt-80 con-user-cards-bugs" style={{ width: "47%", paddingLeft: "5.7%" }}>
            <CardTransactionDocumentsData />
            <CardBillingTransactionReport />
        </div>
    )
}

export default React.memo( RightSideTransactionReport )