import React, { useEffect } from 'react'
import { getMobileOperatingSystem, getMobileOperatingSystem2, getPlatform } from '../../utils/CheckDevice/checkDevice'

const RedirectStore = () => {

    useEffect(() => {
        
        const option1 = getMobileOperatingSystem()
        alert(`1 ${option1}`)

        const option2 = getPlatform()
        alert(`2 ${option2}`)

        const option3 = getMobileOperatingSystem2()
        alert(`3 ${option3}`)
    
        return () => {
            
        }
    }, [])
    

    return (
        <div>RedirectStore</div>
    )
}

export default RedirectStore