import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store/store'
import useGetJobName from '../../../../hooks/useGetJobName/useGetJobName'
import WorkPreferenceIcon from '../../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import moment from 'moment'
import { capitalize } from '../../../../../utils/strings/strings'
import "./CardTransactionData.css"

const CardTransactionData = () => {

    const jobId = useSelector((state: RootState) => state.transactionReports.transactionReport.jobId)
    const { startTime, endTime } = useSelector((state: RootState) => state.transactionReports.transactionReport.schedule)
    const { type } = useSelector((state: RootState) => state.transactionReports.transactionReport.workPreference)

    const jobName = useGetJobName(jobId)

    const formatDateRange = (isoDate1: string, isoDate2: string) => {
        try {
            const date1 = moment(isoDate1);
            const date2 = moment(isoDate2);
            
            if (!date1.isValid() || !date2.isValid()) {
                return "Invalid date format";
            }
            
            const dayOfWeek = date1.format('dddd');
            const month = date1.format('MMMM');
            const dayOfMonth = date1.format('Do'); // 'Do' adds the ordinal suffix (th, st, nd, rd)
            const year = date1.format('YYYY');
            const startTime = date1.format('H:mm'); // 24-hour format for start time
            const endTime = date2.format('H:mm');   // 24-hour format for end time
            
            return `${capitalize(dayOfWeek)}, ${capitalize(month)} ${dayOfMonth} ${year}, ${startTime} to ${endTime}`;
        } catch (error) {
            console.log("error", error)
            return ""
        }
    }

    const date = useMemo(() => formatDateRange(startTime, endTime), [startTime, endTime])

    return (
        <div className="card-transaction-info-report center ml-8 border-radius-6">
            <p className="fs-14 fw-600 mr-14">{jobName}</p>
            <WorkPreferenceIcon type={type} size={2.4} unit={"rem"} />
            <p className="fs-14 fw-200 ml-14">{date}</p>
        </div>
    )
}

export default React.memo( CardTransactionData )