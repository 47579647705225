import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowFixeProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checkDependentValue?: boolean) => void
    onFocus: (name: string) => void
}

const RowFixe = ({ inputValues, isDay, valueCheckBox, onChangeCheckbox, onChange, onFocus }: RowFixeProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "fixedPrice_Day",           placeholder: t("ManageJobs:label_08"),  width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedGimIVA_Day",          placeholder: "Gim IVA",                 width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "fixedGimmerIVA_Day",       placeholder: "Gimmer IVA",              width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "fixedGimworkIVA_Day",      placeholder: "Gimwork IVA",             width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedISR_Day",             placeholder: "ISR",                     width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedRMP_Day",             placeholder: "RMP",                     width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "fixedPrice_Night",           placeholder: t("ManageJobs:label_08"),    width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedGimIVA_Night",          placeholder: "Gim IVA",                   width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "fixedGimmerIVA_Night",       placeholder: "Gimmer IVA",                width: "15.65%",   type: "text",   step: undefined,     readOnly: true },
        { name: "fixedGimworkIVA_Night",      placeholder: "Gimwork IVA",               width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedISR_Night",             placeholder: "ISR",                       width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
        { name: "fixedRMP_Night",             placeholder: "RMP",                       width: "15.65%",   type: "text",   step: undefined,     readOnly: false },
    ], []);

    
    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#F8D7DA"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_03")}
                color="#F8D7DA"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowFixe